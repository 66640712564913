/**
 *药品字典相关
 */

import request from '@/utils/request'

// 查询上海阳光采购平台药品目录
export function smpaDrugList(query) {
  query.downloadFlag=0
  return request({
    url: '/dkm/dict/drug/smpaDrugList',
    method: 'post',
    params: query
  })
}

// 下载上海阳光采购平台药品目录
export function downloadSmpaDrugList(query) {
  query.downloadFlag=1
  return request({
    url: '/dkm/dict/drug/smpaDrugList',
    method: 'post',
    params: query,
    timeout: 5*60 * 1000,
    responseType: 'blob'
  })
}

// 查询国家医保药品目录
export function nhsaDrugList(query) {
  query.downloadFlag=0
  return request({
    url: '/dkm/dict/drug/nhsaDrugList',
    method: 'post',
    params: query
  })
}

// 下载国家医保药品目录
export function downloadNhsaDrugList(query) {
  query.downloadFlag=1
  return request({
    url: '/dkm/dict/drug/nhsaDrugList',
    method: 'post',
    params: query,
    timeout: 5*60 * 1000,
    responseType: 'blob'
  })
}


// 查询是否关联
export function checkDrugHasRelevance(drugType,drugCode) {
  return request({
    url: '/dkm/dict/drug/checkDrugHasRelevance',
    method: 'post',
    params: {drugType:drugType,drugCode:drugCode}
  })
}

// 更新药品备注信息  drugType药品类型 1 国家医保 2上海医保
export function updateDrugComment(drugId,comment,drugType) {
  return request({
    url: '/dkm/dict/drug/updateDrugComment',
    method: 'post',
    params: {drugId:drugId,comment:comment,drugType:drugType}
  })
}


// 查询国家医保自制制剂
export function nhsaHosDrugList(query) {
  query.downloadFlag=0
  return request({
    url: '/dkm/dict/drug/nhsaHosDrugList',
    method: 'post',
    params: query
  })
}

// 下载国家医保自制制剂
export function downloadNhsaHosDrugList(query) {
  query.downloadFlag=1
  return request({
    url: '/dkm/dict/drug/nhsaHosDrugList',
    method: 'post',
    params: query,
    timeout: 5*60 * 1000,
    responseType: 'blob'
  })
}

// 获取国家医保自制制剂药品中的医疗机构列表
export function getNhsaHosDrugHosList() {
  return request({
    url: '/dkm/dict/drug/getNhsaHosDrugHosList',
    method: 'get'
  })
}

// 查询国家医保和上海医保的代码映射关系
export function selectNhsaToSmpaDrugMapping(nhsaDrugCode,smpaDrugCode) {
  return request({
    url: '/dkm/dict/drug/selectNhsaToSmpaDrugMapping',
    method: 'get',
    params: {"nhsaDrugCode":nhsaDrugCode,"smpaDrugCode":smpaDrugCode}
  })
}


