<template>
  <el-container style="background:#fff;height: auto;overflow: overlay;flex-direction: column;">
    <el-row style="padding: 20px;flex-grow: 1;overflow: hidden overlay;" v-loading="loading">
      <el-row v-if="formDisplay.base">
        <el-col :span="24" class="inside-menu-wrap">
          <ul class="inside-menu">
            <li v-for="(item,index) in menulist" :key="index" :class="flag==index?'liActive':''"
                @click="chooseMenu(index)"
            ><a :href="item.href">{{ item.name }}</a></li>
          </ul>
        </el-col>
      </el-row>
      <el-col :span="24" class="title-bar" v-if="formDisplay.base" id="a01">药品及制剂规格</el-col>
      <el-form ref="form" status-icon :model="dkbDp" label-width="120px">

        <el-row v-if="formDisplay.base">
          <el-col :span="24">

            <el-form-item label="药品ID:" v-show="dkbDp.id">
              <el-input v-model="dkbDp.id" :disabled="true" style="width: 300px;float: left"/>
            </el-form-item>

            <el-form-item label="制剂规格:" prop="preparationDesc"
                          :rules="[{ required: true, message: '请选择制剂规格', trigger: 'blur'}]"
            >
              <el-input v-model="dkbDp.preparationDesc" :disabled="true" style="width: 500px;"/>
              <el-button type="primary" size="mini" icon="el-icon-search"
                         style="margin-left: 20px;background: #2B60F8"
                         @click="queryPreparation"
              >制剂规格检索
              </el-button>
              <el-input placeholder="制剂id" v-model="dkbDp.preparationId" style="display: none"/>
              <el-input placeholder="制剂规格id" v-model="dkbDp.specId" style="display: none"/>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row v-if="formDisplay.base">
          <el-col :span="20">
            <!--            <el-form-item label="通用名:" prop="genericName"
                                      :rules="[{ required: true, message: '请输入药品通用名', trigger: 'blur'}]"
                        >
                          <el-input placeholder="请输入通用名" maxlength="100" clearable show-word-limit v-model="dkbDp.genericName"
                                    style="width: 500px;"
                          />
                          <el-button icon="el-icon-document-copy" style="margin-left: 4px;" type="primary" size="small"
                                     @click="copyName" title="复制制剂名称"
                          ></el-button>
                        </el-form-item>-->
          </el-col>
          <!--          <el-col :span="20">
                      <el-form-item label="商品名:" prop="tradeName">
                        <el-input placeholder="请输入商品名" maxlength="100" clearable show-word-limit v-model="dkbDp.tradeName"
                                  style="width: 500px;"
                        />
                      </el-form-item>
                    </el-col>-->
        </el-row>

        <!--        <el-row v-if="formDisplay.base">
                  <el-col :span="20">
                    <el-form-item label="英文名:" prop="englishName">
                      <el-input placeholder="请输入英文名" maxlength="200" clearable show-word-limit v-model="dkbDp.englishName"
                                style="width: 500px;"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>-->

        <el-row v-if="formDisplay.alias">
          <el-form-item
            v-for="(item, index) in dkbDp.dkbDpAliasList"
            :label="index==0?'药品名称:':''"
            :key="'dkbDp-alias-'+index"
            :rules="[{ required: true, message: '请输入药品名称', trigger: 'blur'}]"
            :prop="'dkbDpAliasList.' + index + '.alias'"
          >
            <el-input placeholder="请输入药品名称" v-model="item.alias" style="width: 400px;float: left"
                      maxlength="300" clearable show-word-limit
            />
            <el-select v-model="item.aliasLangue" placeholder="请选择语言" style="width: 80px;float: left">
              <el-option
                v-for="item in languageConcep"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
            <el-form-item style="float: left;margin-bottom: 0px;"
                          :rules="[
          { required: true, message: '请选择名称类型', trigger: 'blur'},
           { validator: validateAliasType, trigger: 'blur'}
          ]"
                          :prop="'dkbDpAliasList.' + index + '.aliasType'"
            >
              <el-select v-model="item.aliasType" placeholder="请选择类型" style="width: 120px;float: left">
                <el-option
                  v-for="item in aliasTypeConcept"
                  :key="item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
            </el-form-item>

            <i class="el-icon-remove" @click.prevent="delAlias(item)"></i>
            <el-radio v-model="dkbDp.aliasPrimary" :label="index" border size="small"
                      @change="primaryChange($event,'aliasPrimary',index)"
                      style="margin-right: 10px;padding: 8px 8px 0 5px;"
            >
              首选项
            </el-radio>
            <el-button icon="el-icon-document-copy" style="margin-left: 4px;display: inline-block;" type="primary"
                       v-if="index == dkbDp.dkbDpAliasList.length-1"
                       @click="copyName(index)" title="复制制剂名称"
            ></el-button>
            <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                       v-if="index == dkbDp.dkbDpAliasList.length-1"
                       @click="addAlias('中文')"
            >
              <i class="el-icon-circle-plus"
                 style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
              ></i>
              <svg-icon icon-class="china" style="width: 24px;height: 16px;"/>
            </el-button>
            <el-button type="primary" plain class="addData" style="width: 80px;padding:0px 0px 0px 16px;"
                       v-if="index == dkbDp.dkbDpAliasList.length-1"
                       @click="addAlias('英语')"
            >
              <i class="el-icon-circle-plus"
                 style="color:#7C9DF7;width:16px;height:16px;display:inline-block;float: left"
              ></i>
              <svg-icon icon-class="yingguoguoqi" style="width: 24px;height: 16px;"/>
            </el-button>
          </el-form-item>

          <el-col :span="20" style="height: 80px;padding-right: 100px;" v-if="formDisplay.alias && !formDisplay.base">
            <el-button type="primary" style="float: right" @click="submitAliasForm">保存别名</el-button>
          </el-col>
        </el-row>


        <el-row v-if="formDisplay.base">

          <el-col :span="24">
            <el-form-item
              v-for="(item, index) in dkbDp.dkbDpManufacturerList"
              :label="index==0?'企业:':''"
              :key="'dkbDp-manufacturer-'+index"
              :rules="[
                { required: true, message: '企业不能为空', trigger: 'blur'},
                 { validator: (rule, value, callback)=>{validateManufacturer(rule, value, callback,index,item)}, trigger: 'blur'}
                ]"
              :prop="'dkbDpManufacturerList.' + index + '.manufacturerId'"
            >
<!--              <el-input placeholder="请输入本位码" v-model="item.standardCode" style="width: 400px;float: left"-->
<!--                        maxlength="50" clearable show-word-limit @blur="standardCodeInputBlur(index)"-->

<!--              >-->
<!--              </el-input>-->
<!--              <i class="el-icon-remove" @click.prevent="delStandardCode(item)"></i>-->
              <el-select style="width: 500px;"
                         v-model="item.manufacturerId"
                         filterable
                         remote
                         reserve-keyword
                         placeholder="请输入关键字搜索"
                         :remote-method="queryManufacturer"
                         :loading="loading"
              >
                <el-option
                  v-for="i in (manufacturerList.length>0?manufacturerList:[{id:item.manufacturerId,name:item.manufacturerName}])"
                  :key="index+'-manufacturerId-'+i.id"
                  :label="i.name"
                  :value="i.id"
                >
                </el-option>
              </el-select>
              <el-select v-model="item.manufacturerType" placeholder="请选择企业类型"
                         style="width: 200px;float: left">
                <el-option
                  v-for="item in manufacturerTypeDict"
                  :key="item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
              <el-popover
                placement="top"
                title="查询结果"
                width="500"
                trigger="click"
              >
                <div>
                  <el-table :data="relationManufacturerList"
                            :height="200" v-loading="loading"
                            border
                            :header-cell-style="{'text-align':'center'}"
                            :cell-style="{'text-align':'center'}"
                            stripe
                  >
                    <el-table-column min-width="200" property="name" label="厂家名称" sortable></el-table-column>
                    <el-table-column min-width="120" property="manufacturerCode" label="厂家代码" ></el-table-column>
                    <el-table-column label="操作" width="80" >
                      <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="selectRelationManufacturer(scope.row,index)">选择</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <el-button icon="el-icon-search" style="margin-left: 4px;display: inline-block;" type="primary"
                           slot="reference" :id="'queryRelationManufacturerBut'+index"
                           @click="queryRelationManufacturer" title="根据本位码和批准文号匹配生产企业"
                ></el-button>
              </el-popover>
              <i class="el-icon-remove" @click.prevent="delManufacturer(item)"></i>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain class="addData" style="width: 500px;"
                         @click="addManufacturer('')"
              >
                <i class="el-icon-circle-plus el-icon--left"
                   style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
                ></i>
                添加生产企业
              </el-button>
            </el-form-item>

          </el-col>






        </el-row>


        <el-row>
          <el-col :span="24" v-if="formDisplay.approvalNum">
            <el-form-item
              v-for="(item, index) in dkbDp.dkbDpApprovalNumList"
              :label="index==0?'批准文号:':''"
              :key="'dkbDp-ApprovalNum-'+index"
              :rules="[
                { required: true, message: '请输入批准文号', trigger: 'blur'},
                 { validator: validateApprovalNum, trigger: 'blur'}
                ]"
              :prop="'dkbDpApprovalNumList.' + index + '.approvalNum'"
            >
              <el-input placeholder="请输入批准文号" v-model="item.approvalNum" style="width: 300px;float: left"
                        maxlength="50" clearable show-word-limit
                        @change.native="approvalNumInputChange(item.approvalNum, index)"
              >
                <el-button slot="append" icon="el-icon-search" @click="showApprovalNumDialog(index)"></el-button>
              </el-input>
              <el-date-picker
                style="width:150px;float: left"
                v-model="item.approveDate"
                type="date"
                :clearable="false"
                placeholder="批准日期"
                value-format="yyyy-MM-dd"
                clearable
              >
              </el-date-picker>
              <el-date-picker
                style="width:150px;float: left"
                v-model="item.certificateDate"
                type="date"
                :clearable="false"
                placeholder="发证日期"
                value-format="yyyy-MM-dd"
                clearable
              >
              </el-date-picker>
              <el-date-picker
                style="width:150px;float: left"
                v-model="item.validityDate"
                type="date"
                :clearable="false"
                placeholder="有效期截止日"
                value-format="yyyy-MM-dd"
                clearable
              >
              </el-date-picker>
              <i class="el-icon-remove" @click.prevent="delApprovalNum(item)"></i>
              <span style="color:#ffba00" v-if=" item.isPzwh=== true">批准文号有误，请校验！</span>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" plain class="addData" style="width: 500px;"
                         @click="addApprovalNum('')"
              >
                <i class="el-icon-circle-plus el-icon--left"
                   style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
                ></i>
                添加批准文号
              </el-button>
            </el-form-item>

          </el-col>
          <el-col :span="20" style="height: 80px;padding-right: 100px;"
                  v-if="formDisplay.approvalNum && !formDisplay.base"
          >
            <el-button type="primary" style="float: right" @click="submitApprovalNumForm">保存批准文号</el-button>
          </el-col>


          <el-col :span="24" v-if="formDisplay.base">
            <el-form-item
              v-for="(item, index) in dkbDp.dkbDpStandardCodeList"
              :label="index==0?'本位码:':''"
              :key="'dkbDp-standardCode-'+index"
              :rules="[
                { required: true, message: '请输入本位码', trigger: 'blur'},
                 { validator: (rule, value, callback)=>{validateStandardCode(rule, value, callback,index)}, trigger: 'blur'}
                ]"
              :prop="'dkbDpStandardCodeList.' + index + '.standardCode'"
            >
              <el-input placeholder="请输入本位码" v-model="item.standardCode" style="width: 400px;float: left"
                        maxlength="50" clearable show-word-limit @blur="standardCodeInputBlur(index)"

              >
              </el-input>
              <i class="el-icon-remove" @click.prevent="delStandardCode(item)"></i>
              <div class="el-form-item__error" v-show="item.flag1 && !item.flag2" style="margin-left: 100px;color: #E6A23C">
                <span>本位码已存在,药品id为:</span>
                <el-link  type="warning" style="font-size: 12px;" title="点击查看药品详情" @click="editDrugProd(item.flag1DrugProdId)">{{item.flag1DrugProdId}}</el-link>
              </div>
              <div class="el-form-item__error" v-show="item.flag2" style="margin-left: 100px;">
                <span>制剂规格和本位码已存在,药品id为:</span>
                <el-link  type="danger" style="font-size: 12px;" title="点击查看药品详情" @click="editDrugProd(item.flag2DrugProdId)">{{item.flag2DrugProdId}}</el-link>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" plain class="addData" style="width: 500px;"
                         @click="addStandardCode('')"
              >
                <i class="el-icon-circle-plus el-icon--left"
                   style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
                ></i>
                添加本位码
              </el-button>
            </el-form-item>

          </el-col>



          <el-col :span="18" v-if="formDisplay.specDes">
            <el-form-item
              v-for="(item, index) in dkbDp.dkbDpSpecDescList"
              :label="index==0?'规格描述:':''"
              :key="'dkbDp-ApprovalNum-'+index"
              :rules="[{ required: true, message: '请输入规格描述', trigger: 'blur'}]"
              :prop="'dkbDpSpecDescList.' + index + '.specDesc'"
            >
              <el-input type="textarea" placeholder="请输入规格描述" v-model="item.specDesc" style="width: 500px;float: left"
                        maxlength="500" clearable show-word-limit
              />

              <i class="el-icon-remove" @click.prevent="delSpecDesc(item)"></i>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" plain class="addData" style="width: 500px;"
                         @click="addSpecDesc"
              >
                <i class="el-icon-circle-plus el-icon--left"
                   style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"
                ></i>
                添加规格描述
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="formDisplay.base">
          <el-col :span="8">
            <el-form-item label="国产进口标识:" prop="domesticOrImport">
              <el-radio-group v-model="dkbDp.domesticOrImport" size="small">
                <el-radio-button v-for="item in domesticOrImportDict"
                                 :label="item.conceptId"
                                 :key="item.conceptId"
                >
                  {{ item.conceptVal }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="原研药标记:" prop="originalDrug">
              <el-radio-group v-model="dkbDp.originalDrug" size="small">
                <el-radio-button :label='2'>未知</el-radio-button>
                <el-radio-button :label='1'>是</el-radio-button>
                <el-radio-button :label='0'>否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="一致性评价标记:" prop="consistencyEvaluation">
              <el-radio-group v-model="dkbDp.consistencyEvaluation" size="small">
                <el-radio-button :label='2'>未知</el-radio-button>
                <el-radio-button :label='1'>是</el-radio-button>
                <el-radio-button :label='0'>否</el-radio-button>

              </el-radio-group>
            </el-form-item>
          </el-col>
<!--          <el-col :span="20" v-if="false">-->
<!--            <el-form-item label="上市许可持有人:" prop="mahId">-->
<!--              <el-select style="width: 500px;"-->
<!--                         v-model="dkbDp.mahId"-->
<!--                         filterable-->
<!--                         remote-->
<!--                         reserve-keyword-->
<!--                         placeholder="请输入关键字搜索"-->
<!--                         :remote-method="queryManufacturer"-->
<!--                         :loading="loading"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in (manufacturerList.length>0?manufacturerList:[{id:dkbDp.mahId,name:dkbDp.mahName}])"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id"-->
<!--                >-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--              <el-button icon="el-icon-document-copy" style="margin-left: 4px;display: inline-block;" type="primary"-->
<!--                         @click="copyManufacturerMsg" title="复制生产企业名称"-->
<!--              ></el-button>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>

        <el-row v-if="formDisplay.pdProd">
          <el-col :span="24" class="title-bar" id="a03">包装药品</el-col>
          <el-col :span="20">
            <span style="font-size: 16px;">相关包装列表</span>
            <span style="font-size: 14px;color: #2949A5">（注：由于组合包装涉及多个表，此处只做信息查看，不做数据维护。需到“组合包装管理”功能界面进行维护）</span>
          </el-col>
          <el-col :span="4" style="padding-bottom: 10px;">
            <el-button type="primary"
                       style="float:right;"
                       @click="editPdProd()"
            >添加包装药品
            </el-button>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 0px 15px 15px;" v-if="formDisplay.pdProd">
          <el-col :span="24">
            <el-table
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="dkbDp.dkbPdProdList"
              :height="pdProdTableHeight"
              ref="rno"
            >
              <el-table-column property="id" label="包装药品ID" width="80"></el-table-column>
              <el-table-column label="包装药品名称" width="120">
                <template slot-scope="scope">
                  <span style="margin-right: 5px;">{{ scope.row.drugName }}</span>
                  <span v-for="item in scope.row.drugCategoryList"
                        :key="item.id"
                  >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column property="packSpecDesc" label="规格描述" width="80"></el-table-column>
              <el-table-column property="packTypeDesc" label="包装类型" width="80"></el-table-column>
              <el-table-column property="packQty" label="数量" width="80">
                <template slot-scope="scope">
                  {{ scope.row.packQty }}
                  {{ scope.row.specUnitDesc }}
                  <span style="font-size: 18px;font-weight: bold;">/</span>
                  {{ scope.row.packQtyUnitDesc }}
                </template>
              </el-table-column>
              <el-table-column label="包装材料" min-width="200">
                <template slot-scope="scope">
                  <P style="text-align: left" v-for="(item,index) in scope.row.pdProdMatList"
                     :key="item.id+index+item.wrapperDesc+'pdProdMatList'"
                  >
                    {{ '[' + (index + 1) + ']' }}{{ item.wrapperDesc }}
                    <span v-show="index<scope.row.pdProdMatList.length-1">;</span>
                    <span v-show="!isEmpty(item.stateMedicareNum)" style="margin-left: 10px;">国家医保代码:{{ item.stateMedicareNum}}</span>
                    <span v-show="!isEmpty(item.shMedicareNum)" style="margin-left: 10px;">上海医保代码:{{ item.shMedicareNum}}</span>
                  </P>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" key="status" width="74" fixed="right">
                <template slot-scope="scope">
                  <el-switch
                    class="switch"
                    active-color="#5DB730"
                    active-text="启用"
                    inactive-text="停用"
                    inactive-color="#B5CCF5"
                    :disabled="scope.row.packType==2"
                    v-model="scope.row.status"
                    active-value="0"
                    inactive-value="1"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120" fixed="right">
                <template slot-scope="scope">

                  <el-button @click="editPdProd(scope.$index,scope.row)" :disabled="scope.row.packType==2" type="text"
                             size="small"
                  >编辑
                  </el-button>
                  <el-button @click="copyPdProd(scope.$index,scope.row)" :disabled="scope.row.packType==2" type="text"
                             size="small"
                  >复制
                  </el-button>
                  <el-button @click="editPdSpec(scope.$index,scope.row)" :disabled="scope.row.packType==2" type="text"
                             size="small"
                  >拆分
                  </el-button>
                  <el-button @click="viewPdProdMatList(scope.$index,scope.row)" :disabled="scope.row.packType==2"
                             type="text"
                             size="small"
                  >包装材料维护
                  </el-button>
                  <el-button @click="delPdProd(scope.$index,scope.row)" :disabled="scope.row.packType==2" type="text"
                             size="small"
                             style="color: #FF3333;"
                  >删除
                  </el-button>

                  <el-button
                    :disabled="scope.row.packType==2"
                    size="small"
                    type="text"
                    @click="handleResource(scope.$index,scope.row)"
                  >来源
                  </el-button>

                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
          <el-col :span="24" style="height: 60px;padding: 10px;" v-if="formDisplay.pdProd && !formDisplay.base">
            <el-button type="primary" style="float: right" @click="submitPdProdForm">保存包装药品
            </el-button>
          </el-col>
          <el-col style="height: 50px;padding: 10px;">

          </el-col>
        </el-row>
        <el-row v-if="formDisplay.specIngredient">
          <el-col :span="24" class="title-bar" id="a02">药品成分(包含成分)</el-col>
          <el-col :span="24" style="padding-bottom: 10px;">
            <el-button type="primary"
                       style="float:right;"
                       @click="editSpecIngredient"
            >添加成分
            </el-button>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 0px 15px 15px;" v-if="formDisplay.specIngredient">
          <el-col :span="24">
            <el-table
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="dkbDp.dkbDpSpecIngredientList"
              :height="specIngredientTableHeight"
              ref="rno"
            >
              <el-table-column property="id" label="成分ID" width="100" sortable></el-table-column>
              <el-table-column property="substanceName" label="物质" min-width="100" sortable></el-table-column>
              <el-table-column property="substanceQtyMax" label="物质数量" width="100">
                <template slot-scope="scope">
                  {{ scope.row.substanceQtyMax }}
                  {{ scope.row.substanceQtyUnitDesc }}
                </template>
              </el-table-column>
              <el-table-column property="ingredientTypeDesc" label="成分类型" width="100" sortable></el-table-column>
              <el-table-column property="substanceReferenceDesc" label="物质参考说明" min-width="100"></el-table-column>
              <el-table-column label="操作" width="150" fixed="right">
                <template slot-scope="scope">
                  <el-button @click="editSpecIngredient(scope.$index,scope.row)" type="text" size="small">编辑</el-button>
                  <el-button @click="delSpecIngredient(scope.$index,scope.row)" type="text" size="small"
                             style="color: #FF3333;"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>
        <el-col :span="24" v-if="formDisplay.specIngredient && !formDisplay.base">
          <el-button type="primary" style="float: right" @click="submitSpecIngredientForm">
            保存成分
          </el-button>
        </el-col>
        <el-col :span="20" class="bottom-bar"
                v-if="formDisplay.base"
        >

        </el-col>
      </el-form>

    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="20" class="bottom-bar"
              style="height: 80px;padding-right: 0px;position: fixed;right: 30px;bottom: 10px;"
              v-if="formDisplay.base"
      >
        <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="dkbDp.id">下一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="editPrev" v-show="dkbDp.id">上一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="handleReturn">返回</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(1)" >保存</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(2)" >保存并关闭</el-button>
      </el-col>
    </el-row>

    <el-dialog v-dialogDrag title="制剂检索" append-to-body width="60%" :visible.sync="preparation.dialogVisible"
               :close-on-click-modal="false"
    >


      <el-form ref="preparationQueryForm" status-icon :model="preparation">
        <el-form-item>
          <el-input placeholder="请输入关键字" v-model="preparation.searchValue" maxlength="20" clearable
                    show-word-limit style="width: 400px;float: left"
                    @keydown.enter.native="queryPreparation"
          />
          <el-input style="display: none"/>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"
                     @click="queryPreparation"
          >检索
          </el-button>
          <el-button type="primary" icon="el-icon-circle-plus-outline"
                     style="margin-left: 20px;background: #2B60F8;float:right;"
                     @click="addPreparation"
          >新增制剂
          </el-button>
        </el-form-item>
      </el-form>


      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="preparation.tableData"
        :height="300"
        :default-sort="{prop: 'preparationName', order: 'descending'}"
        @sort-change="ppSpecTableSortChange"
      >
        <el-table-column property="preparationSpecId" label="规格id" width="100" sortable="custom"></el-table-column>
        <el-table-column property="preparationName" label="制剂名称" min-width="150" sortable="custom"></el-table-column>
        <el-table-column property="preparationForm" label="制剂剂型" width="100" sortable="custom"></el-table-column>
        <el-table-column property="preparationSpecDesc" label="规格描述" min-width="150" sortable="custom"
        ></el-table-column>
        <!--        <el-table-column property="drugName" label="药物成分"></el-table-column>-->
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini" type="text"
              @click="choosePreparation(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty" v-if="isDrugProd === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
      <pagination
        v-show="preparation.total>0"
        :total="preparation.total"
        :page.sync="preparation.pageNum"
        :limit.sync="preparation.pageSize"
        @pagination="queryPreparation"
      />

    </el-dialog>

    <el-dialog v-dialogDrag title="成分编辑" append-to-body width="60%" :visible.sync="addSpecIngredientDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="padding: 20px;">
        <el-form ref="addSpecIngredientForm" status-icon :model="specIngredientForm" label-width="120px"
        >
          <el-row>

            <el-col :span="14">
              <el-form-item label="成分物质:" prop="substanceId"
                            :rules="specIngredientFormRule.substanceId"
              >
                <el-select
                  v-model="specIngredientForm.substanceId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词搜索"
                  :remote-method="querySubstanceName"
                  :loading="loading"
                  style="width: 280px;float: left"

                >
                  <el-option
                    v-for="item in substanceList"
                    :key="'substanceList'+item.substanceId"
                    :label="item.name"
                    :value="item.substanceId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="成分类型:" prop="ingredientType">
                <el-select v-model="specIngredientForm.ingredientType"
                           style="width: 150px;float: left"
                >
                  <el-option
                    v-for="item in drugIngredientTypeDict"
                    :key="'drugIngredientTypeDict'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>

            <el-col :span="14">
              <el-form-item label="物质数量:" prop="substanceQtyMax">
                <el-input-number v-model="specIngredientForm.substanceQtyMax" :min="0" controls
                                 controls-position="right" :precision="4" style="width: 150px;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="物质数量单位:" prop="substanceQtyUnitId">
                <el-select v-model="specIngredientForm.substanceQtyUnitId"
                           style="width: 150px;float: left"
                           filterable
                           :filter-method="substanceQtyUnitFilter"
                           @focus="quantityUnitDictOptions=quantityUnitDict"
                >
                  <el-option label="" value></el-option>
                  <el-option
                    v-for="item in quantityUnitDictOptions"
                    :key="'quantityUnitDict'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="物质参考说明:" prop="substanceReferenceDesc"
              >
                <el-input type="textarea" :rows="6" placeholder="请输入物质参考说明"
                          v-model="specIngredientForm.substanceReferenceDesc"
                          maxlength="200" clearable show-word-limit
                          style="width: 500px;float: left;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-button type="primary" size="small"
                         style="margin-left: 20px;background: #2B60F8;float:right;"
                         @click="confirmSpecIngredient"
              >确定
              </el-button>
              <el-button style="float: right;margin-right: 10px;"
                         size="small" @click="cancelSpecIngredient"
              >取消
              </el-button>
            </el-col>
          </el-row>


        </el-form>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="编辑包装药品" width="60%" append-to-body :visible.sync="editPdProdDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="padding: 20px;">
        <el-form ref="editPdProdForm" status-icon :model="pdProdForm" label-width="120px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="包装药品名称:" prop="drugName"
                            :rules="[{ required: true, message: '请输入包装药品名称', trigger: 'blur'}]"
              >
                <el-input v-model="pdProdForm.drugName" style="width: 350px;" maxlength="300" clearable
                          show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="包装规格描述:" prop="packSpecDesc"
                            :rules="[{ required: true, message: '请输入包装规格描述', trigger: 'blur'}]"
              >
                <el-input v-model="pdProdForm.packSpecDesc" style="width: 350px;" maxlength="200" clearable
                          show-word-limit
                />
                <el-tooltip class="item" effect="light" content="根据已填写的数量填充包装规格描述" placement="top-start">
                  <el-link type="primary" :underline="false"   @click="fillPackSpecDesc"
                           style="margin-left: 10px;">填写包装规格描述</el-link>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="数量:" prop="packQty"
                            :rules="[{ required: true, message: '请输入数量', trigger: 'blur'}]"
              >
                <el-input-number v-model="pdProdForm.packQty" :min="0" :max="100000000" controls controls-position="right"
                                 :precision="2" style="width: 220px;"
                />
                <el-select v-model="pdProdForm.specUnit"
                           style="width: 100px;"
                           filterable
                           :filter-method="packQtyUnitFilter"
                           @focus="packQtyUnitDictOptions=packQtyUnitDict"
                >
                  <el-option
                    v-for="item in packQtyUnitDictOptions"
                    :key="'pdProdForm.specUnit'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
                <span style="margin: 5px;font-size: 20px;font-weight: bold;">/</span>
                <el-select v-model="pdProdForm.packQtyUnitId"
                           style="width: 100px;"
                           filterable
                           :filter-method="packQtyUnitFilter"
                           @focus="packQtyUnitDictOptions=packQtyUnitDict"
                >
                  <el-option
                    v-for="item in packQtyUnitDictOptions"
                    :key="'pdProdForm.packQtyUnitId'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-button type="primary"
                         size="small"
                         style="margin-left: 20px;background: #2B60F8;float:right;"
                         @click="confirmPdProd"
              >确定
              </el-button>
              <el-button style="float: right;margin-right: 10px;"
                         size="small" @click="cancelPdProd"
              >取消
              </el-button>
            </el-col>
          </el-row>

        </el-form>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="包装药品明细拆分" width="60%" append-to-body :visible.sync="editPdSpecDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="editPdSpecForm" status-icon :model="pdSpecForm" label-width="10px" :inline="true">
        <el-form-item label="" prop="level1PackQty"
                      :rules="[{ required: true, message: '请输入数量', trigger: 'blur'}]"
        >
          <el-input-number v-model="pdSpecForm.level1PackQty" :min="0" :max="10000" controls controls-position="right"
                           :precision="2" style="width: 120px;"
          />
          <el-select v-model="pdSpecForm.specUnit"
                     style="width: 110px;"
                     disabled
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'specUnit'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <span style="margin: 5px;font-size: 20px;font-weight: bold;">/</span>
          <el-select v-model="pdSpecForm.level1PackQtyUnitId"
                     style="width: 110px;"
                     filterable
                     :filter-method="packQtyUnitFilter"
                     @change="$forceUpdate()"
                     @focus="packQtyUnitDictOptions=packQtyUnitDict"
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'specUnit'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <span style="margin: 10px;font-size: 20px;font-weight: bold;">*</span>

        </el-form-item>

        <el-form-item   label="" prop="level2PackQty"
                        :rules="[{ required: true, message: '请输入', trigger: 'blur'}]"
        >
          <el-input-number v-model="pdSpecForm.level2PackQty" :min="0" :max="10000" controls controls-position="right"
                           :precision="2" style="width: 120px;"
          />
          <el-select v-model="pdSpecForm.level1PackQtyUnitId"
                     style="width: 100px;"
                     disabled
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'specUnit'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <span style="margin: 5px;font-size: 20px;font-weight: bold;">/</span>
          <el-select v-model="pdSpecForm.packQtyUnitId"
                     style="width: 100px;"
                     disabled
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'packQtyUnitId'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="24" style="margin: 10px 0px;">
            <el-button type="primary" style="float: right;margin-right: 10px;" icon="el-icon-circle-check"
                       size="small" @click="confirmEditPdSpec"
            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-close"
                       size="small" @click="cancelEditPdSpec"
            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>


    <el-dialog v-dialogDrag title="引用来源管理" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list"
        >
          <el-table-column property="date" label="引用资源类型" align="center" width="180">
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择物质名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 150px"
                  @change="resourceTypeChange($event,scope.$index)"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" align="center" label="引用资源名称">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width:300px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="100">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                istyle="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;" icon="el-icon-circle-check"
                     size="small" @click="saveResource"
          >确定
          </el-button>
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-close"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>


    <el-dialog v-dialogDrag title="选择本位码" width="60%" append-to-body :visible.sync="standardCodeSelectDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="height:400px;overflow: auto">
        <p style="font-size: 15px;font-weight: bold;">根据当前已输入的批准文号，匹配到多个本位码，请选择一个本位码</p>
        <el-divider></el-divider>
        <ul style="font-size: 15px;">
          <li v-for="(item, index) in cfdaDrugList">
            <span style="margin-right: 10px;">{{ item.approvalNum }}:</span>
            <span style="margin-right: 10px;">{{ item.standardCode }}</span>
            <span style="margin-right: 10px;">{{ item.drugName }}</span>
            <span style="margin-right: 10px;">{{ item.standardCodeRemark }}</span>
            <el-tag style="margin-right: 10px;" size="small" v-if="item.existFlag">已添加</el-tag>
            <el-button type="text" @click="selectStandardCode(index)" v-if="!item.existFlag">选择</el-button>
          </li>
        </ul>
      </el-row>

    </el-dialog>


    <el-dialog v-dialogDrag title="包装材料明细" width="60%" append-to-body :visible.sync="pdProdMatListDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="editPdProdMat()"
          >添加
          </el-button>
        </el-col>
      </el-row>

      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="pdProdMatList"
        :height="300"
      >
        <!--          <el-table-column property="id" label="包装材料ID" width="100"></el-table-column>-->
        <el-table-column property="id" label="包装材料ID" width="100"></el-table-column>
        <el-table-column property="wrapperDesc" label="包装材料描述" min-width="150"></el-table-column>
        <el-table-column property="storage" label="储藏条件" min-width="100"></el-table-column>
        <el-table-column
          label="有效期"
          width="80"
        >
          <template slot-scope="scope">
            {{ scope.row.indate }} {{ scope.row.indateUnitDesc }}
          </template>
        </el-table-column>
        <el-table-column property="barcode69" label="编码" min-width="200">
          <template slot-scope="scope">
            <p v-show="scope.row.barcode69">69条形码: {{ scope.row.barcode69 }}</p>
            <p v-show="scope.row.stateMedicareNumList.toString()!=''">国家医保统编码:
            <span  v-for="(item,index) in scope.row.stateMedicareNumList" :key="item+'stateMedicareNum'">
              {{ item}}<span v-show="index<scope.row.stateMedicareNumList.length-1">;</span>
            </span>
            </p>
            <p v-show="scope.row.shMedicareNumList.toString()!=''">上海医保统编码:
              <span  v-for="(item,index) in scope.row.shMedicareNumList" :key="item+'shMedicareNum'">
              {{ item}}<span v-show="index<scope.row.shMedicareNumList.length-1">;</span>
            </span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini" type="text"
              @click="editPdProdMat(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
              size="mini" type="text"
              style="color: #FF3333;"
              @click="delPdProdMat(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="margin: 10px 0px;">
          <el-button type="primary" style="float: right;margin-right: 10px;" icon="el-icon-circle-check"
                     size="small" @click="confirmEditPdProdMatList"
          >确定
          </el-button>
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-close"
                     size="small" @click="pdProdMatListDialogVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="编辑包装材料" width="600px" append-to-body :visible.sync="editPdProdMatDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="padding: 20px;">
        <el-form ref="editPdProdMatForm" status-icon :model="editPdProdMatForm" label-width="130px">

          <el-row>
            <el-col :span="24">
              <el-form-item label="包装材料描述:" prop="wrapperDesc"
                            :rules="[
                              { required: true, message: '请输入包装材料描述', trigger: 'blur'},
                               { validator: validateWrapperDesc, trigger: 'blur'}
                               ]"
              >
                <el-input type="textarea" :rows="6" placeholder="请输入包装材料描述"
                          v-model="editPdProdMatForm.wrapperDesc"
                          maxlength="1000" clearable show-word-limit
                          style="width: 400px;float: left;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="储藏条件:" prop="storage">
                <el-input v-model="editPdProdMatForm.storage" style="width: 400px;" maxlength="200" clearable
                          show-word-limit
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="有效期:" prop="indate"
                            :rules="[]"
              >
                <el-input-number v-model="editPdProdMatForm.indate" :min="0" :max="10000" controls
                                 controls-position="right"
                                 :precision="0" style="width: 120px;float: left"
                />
                <el-select v-model="editPdProdMatForm.indateUnit"
                           style="width: 100px;float: left"
                >
                  <el-option
                    v-for="item in timeUnitDict"
                    :key="'indateUnit'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="69条形码:" prop="barcode69">
                <el-input v-model="editPdProdMatForm.barcode69" style="width: 400px;" maxlength="30" clearable
                          show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item
                v-for="(item, index) in editPdProdMatForm.stateMedicareNumList"
                :label="index==0?'国家医保统编码:':''"
                :key="'stateMedicareNum-'+index"
                :rules="[ { validator: validateMedicareNum, trigger: 'change'}]"

                :prop="'stateMedicareNumList.' + index"
              >
                <el-input placeholder="请输入国家医保统编码" v-model="editPdProdMatForm.stateMedicareNumList[index]"
                          style="width: 320px;float: left"
                          maxlength="30" clearable show-word-limit
                          @paste.native.capture.prevent="item==''"
                          @focus="medicareNumInputFocus('国家',index)"
                />
                <i class="el-icon-remove"
                   v-show="editPdProdMatForm.stateMedicareNumList.length>1"
                   @click.prevent="delMedicareNum(item,index,'国家')"></i>
                <i class="el-icon-circle-plus"
                   v-show="index==editPdProdMatForm.stateMedicareNumList.length-1 && editPdProdMatForm.stateMedicareNumList.length<5"
                   style="font-size: 20px;color: #2B60F8;margin-left: 4px;" @click.prevent="addMedicareNum('国家')"></i>

              </el-form-item>

            </el-col>


            <el-col :span="24">
              <el-form-item
                v-for="(item, index) in editPdProdMatForm.shMedicareNumList"
                :label="index==0?'上海医保统编码:':''"
                :key="'shMedicareNum-'+index"
                :rules="[ { validator: validateMedicareNum}]"

                :prop="'shMedicareNumList.' + index"
              >
                <el-input placeholder="请输入上海医保统编码" v-model="editPdProdMatForm.shMedicareNumList[index]"
                          style="width: 320px;float: left"
                          maxlength="30" clearable show-word-limit
                          @paste.native.capture.prevent="item==''"
                          @focus="medicareNumInputFocus('上海',index)"
                />
                <i class="el-icon-remove"
                   v-show="editPdProdMatForm.shMedicareNumList.length>1"
                   @click.prevent="delMedicareNum(item,index,'上海')"></i>
                <i class="el-icon-circle-plus"
                   v-show="index==editPdProdMatForm.shMedicareNumList.length-1 && editPdProdMatForm.shMedicareNumList.length<5"
                   style="font-size: 20px;color: #2B60F8;margin-left: 4px;" @click.prevent="addMedicareNum('上海')"></i>

              </el-form-item>

            </el-col>



          </el-row>

          <el-row>
            <el-col :span="24">
              <el-button type="primary"
                         size="small"
                         style="margin-left: 20px;background: #2B60F8;float:right;"
                         @click="confirmPdProdMat"
              >确定
              </el-button>
              <el-button style="float: right;margin-right: 10px;"
                         size="small" @click="editPdProdMatDialogVisible=false"
              >取消
              </el-button>
            </el-col>
          </el-row>

        </el-form>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="批准文号检索" append-to-body width="70%" :visible.sync="approvalNumDialog.visible"
               :close-on-click-modal="false"
    >
      <el-form ref="approvalNumQueryForm" status-icon :model="approvalNumDialog.queryParams">
        <el-form-item>
          <el-input placeholder="请输入药品名称" v-model="approvalNumDialog.queryParams.drugName" maxlength="20" clearable
                    show-word-limit style="width: 300px;float: left"
                    @keydown.enter.native="queryApprovalNum"
          />
          <el-input placeholder="请输入药品厂家" v-model="approvalNumDialog.queryParams.manufacturer" maxlength="20" clearable
                    show-word-limit style="width: 300px;float: left;margin-left: 10px;"
                    @keydown.enter.native="queryApprovalNum"
          />
          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"
                     @click="queryApprovalNum"
          >检索
          </el-button>
        </el-form-item>
      </el-form>


      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="approvalNumDialog.tableData"
        :height="300"
        :default-sort="{prop: 'preparationName', order: 'descending'}"
        @sort-change="approvalNumTableSortChange"
      >
        <el-table-column property="drugName" label="药品名称" min-width="200" sortable="custom"></el-table-column>
        <el-table-column property="dosageForm" label="剂型" width="100" sortable="custom"></el-table-column>
        <el-table-column property="spec" label="规格" min-width="150" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.standardCodeRemark?scope.row.standardCodeRemark:scope.row.spec }}
          </template>
        </el-table-column>
        <el-table-column property="domesticOrImport" label="国产/进口" min-width="90" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.domesticOrImport==1?'国产':'进口'}}
          </template>
        </el-table-column>
        <el-table-column property="manufacturerEn" label="生产企业" min-width="150" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.manufacturerZh}}
            {{ scope.row.manufacturerEn}}
          </template>
        </el-table-column>
        <el-table-column property="mahEn" label="上市许可持有人" min-width="150" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.mahEn}}
            {{ scope.row.mahZh}}
          </template>
        </el-table-column>
        <el-table-column property="approvalNum" label="批准文号" width="150" sortable="custom"></el-table-column>
        <el-table-column property="standardCode" label="本位码" width="150" sortable="custom"></el-table-column>
        <el-table-column label="操作" width="70" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini" type="text"
              @click="chooseApprovalNum(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="approvalNumDialog.total>0"
        :total="approvalNumDialog.total"
        :page.sync="approvalNumDialog.queryParams.pageNum"
        :limit.sync="approvalNumDialog.queryParams.pageSize"
        @pagination="queryApprovalNum"
      />

    </el-dialog>


    <el-dialog v-dialogDrag title="国家医保药品目录" width="80%" append-to-body :visible.sync="nhsaDrugDictDialogVisible"
               :close-on-click-modal="false"
    >
      <div>
        <el-form ref="drugDictQueryForm" status-icon :model="dictDrugForm">
          <el-form-item>
            <el-input placeholder="请输入药品名称/批准文号/本位码" clearable v-model="dictDrugForm.drugName"
                      show-word-limit style="width: 300px;float: left"
                      @keydown.enter.native="selectDictDrug('国家')"
            />
            <el-input style="display: none"/>
            <el-button type="primary" icon="el-icon-search" @click="selectDictDrug('国家')"
                       style="margin-left: 20px;background: #2B60F8"
            >检索
            </el-button>
            <span style="margin:0px 10px 0px 30px;">快捷搜索:</span>
            <el-link type="primary" style="margin-right:15px;" @click="quickSearchDictDrug($event,'国家')"
                     v-for="(item, index) in dkbDp.dkbDpAliasList" :key="index+item.alias">{{item.alias}}</el-link>
            <el-link type="primary" style="margin-right:15px;" @click="quickSearchDictDrug($event,'国家')"
                     v-for="(item,index) in dkbDp.dkbDpApprovalNumList" :key="index+item.approvalNum">{{item.approvalNum}}</el-link>
            <el-link type="primary" style="margin-right:15px;" @click="quickSearchDictDrug($event,'国家')"
                     v-for="(item,index) in dkbDp.dkbDpStandardCodeList" :key="index+item.standardCode">{{item.standardCode}}</el-link>
          </el-form-item>


        </el-form>
        <el-table :data="dictDrugTableData"
                  :height="300" v-loading="loading"
                  border
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  @sort-change="drugDictTableSortChange"
                  stripe
        >
          <el-table-column min-width="150" property="registeredProductName" label="药品名称" sortable="custom"
          ></el-table-column>
          <el-table-column property="displayName" label="规格剂型" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.registeredMedicineModel }}{{ scope.row.registeredOutlook }}
            </template>
          </el-table-column>
          <el-table-column min-width="100" property="realitySpec" label="实际规格" ></el-table-column>
          <el-table-column min-width="150" property="materialName" label="包装">
            <template slot-scope="scope">
              {{ scope.row.materialName }}({{ scope.row.factor }}/{{ scope.row.minUnit }}/{{
                scope.row.unit
              }})
            </template>
          </el-table-column>
          <el-table-column min-width="150" property="companyNamesc" label="厂家" sortable="custom"
          ></el-table-column>
          <el-table-column min-width="100" property="approvalCode" label="批准文号" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="goodsStandardCode" label="本位码" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="goodsCode" label="国家医保编码" sortable="custom"></el-table-column>
          <el-table-column min-width="80" property="comment" label="备注信息" ></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="selectDrugDictTableRow(nowEditInputIndex, scope.row,'国家')"
              >选择
              </el-button>
              <el-button
                size="mini" type="text"
                @click="editDrugComment(scope.row,1)"
              >编辑备注
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="dictDrugForm.total>0"
          :total="dictDrugForm.total"
          :page.sync="dictDrugForm.pageNum"
          :limit.sync="dictDrugForm.pageSize"
          @pagination="selectDictDrug('国家')"
        />
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag title="上海医保药品目录" width="80%" append-to-body :visible.sync="smpaDrugDictDialogVisible"
               :close-on-click-modal="false"
    >
      <div>
        <el-form ref="drugDictQueryForm" status-icon :model="dictDrugForm">
          <el-form-item>
            <el-input placeholder="请输入药品名称/批准文号/本位码" clearable v-model="dictDrugForm.drugName"
                      show-word-limit style="width: 300px;float: left"
                      @keydown.enter.native="selectDictDrug('上海')"
            />
            <el-input style="display: none"/>
            <el-button type="primary" icon="el-icon-search" @click="selectDictDrug('上海')"
                       style="margin-left: 20px;background: #2B60F8"
            >检索
            </el-button>
            <span style="margin:0px 10px 0px 30px;">快捷搜索:</span>
            <el-link type="primary" style="margin-right:15px;" @click="quickSearchDictDrug($event,'上海')"
                     v-for="(item, index) in dkbDp.dkbDpAliasList"
                     :key="index+item.alias">{{item.alias}}</el-link>
            <el-link type="primary" style="margin-right:15px;" @click="quickSearchDictDrug($event,'上海')"
                     v-for="(item, index) in dkbDp.dkbDpApprovalNumList" :key="index+item.approvalNum">{{item.approvalNum}}</el-link>
            <el-link type="primary" style="margin-right:15px;" @click="quickSearchDictDrug($event,'上海')"
                     v-for="(item,index) in dkbDp.dkbDpStandardCodeList" :key="index+item.standardCode">{{item.standardCode}}</el-link>
          </el-form-item>
        </el-form>
        <el-table :data="dictDrugTableData"
                  :height="300" v-loading="loading"
                  border
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  @sort-change="drugDictTableSortChange"
                  stripe
        >
          <el-table-column min-width="150" property="yptym" label="药品名称" sortable="custom"></el-table-column>
          <el-table-column property="displayName" label="剂型规格包装" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.jx }}--{{ scope.row.ggbz }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" property="ggbzwzbs" label="包装"></el-table-column>
          <el-table-column min-width="150" property="scqy" label="厂家" sortable="custom"></el-table-column>
          <el-table-column min-width="100" property="pzwh" label="批准文号" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="bwm" label="本位码" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="tbdm" label="上海医保编码" sortable="custom"></el-table-column>
          <el-table-column min-width="80" property="comment" label="备注信息" ></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="selectDrugDictTableRow(nowEditInputIndex, scope.row,'上海')"
              >选择
              </el-button>
              <el-button
                size="mini" type="text"
                @click="editDrugComment(scope.row,2)"
              >编辑备注
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="dictDrugForm.total>0"
          :total="dictDrugForm.total"
          :page.sync="dictDrugForm.pageNum"
          :limit.sync="dictDrugForm.pageSize"
          @pagination="selectDictDrug('上海')"
        />
      </div>
    </el-dialog>


    <el-dialog v-dialogDrag title="备注信息编辑" width="500px" append-to-body
               :visible.sync="editDrugCommentDialogVisible"
               :close-on-click-modal="false"
    >
      <el-input type="textarea" :rows="6" placeholder="请输入备注信息"
                v-model="drugCommentForm.comment"
                maxlength="500" clearable show-word-limit
                style="width: 450px;"
      />
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editDrugCommentDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditDrugComment">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import { info, saveOrUpdate, getRecommendSpecIngredient, checkFields, list ,checkStandardCodeAndPpSpecId} from '@/api/dkm/drugProd'
import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
import { selectManufacturerNameByKey,selectRelationManufacturer } from '@/api/dkm/manufacturer'
import { querySpecList, getAliasInfo } from '@/api/dkm/preparationSpecification'
import { selectSubstanceNameList } from '@/api/dkm/substance'
import { isEmpty } from '@/utils/common'
import { getResourceCategory, getReferenceInfo } from '@/utils/resourceReference'
import { selectReferenceInfo, saveOrUpdateInfoList, resourceNameQuery } from '@/api/dkm/resource'
import tableConst from '@/const/tableConst'
import { DEEP_CLONE } from '../../../utils/constants'
import $ from 'jquery'
import '@/utils/nav.js'
import { selectByStandardCode, selectByApprovalNumList,list as cfdaDrugList } from '@/api/dkm/dictCfdaDrug'
import { deepClone } from '../../../utils'
import { smpaDrugList, nhsaDrugList,checkDrugHasRelevance,updateDrugComment,selectNhsaToSmpaDrugMapping } from '@/api/dkm/dictDrug'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'

export default {
  name: 'DrugProdEdit',
  props: {
    dkbDpData: {
      type: Object
    },
    /*控制编辑页面各个模块表单的显示与隐藏*/
    formDisplayData: {
      type: Object
    }
  },
  watch: {
    /*监听dkbDpData值的变化*/
    dkbDpData: {
      deep: true,
      handler(newVal, oldVal) {
        this.initDkbDpInfo(newVal)
        //this.dkbDp=newVal
      }
    },
    formDisplayData: {
      deep: true,
      handler(newVal, oldVal) {
        Object.assign(this.formDisplay, newVal)
      }
    },
    dkbDp: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.editTimes == 0) {
          this.editTimes++
        } else {
          this.editFlag = true
          // 通知父组件，数据变化了
          this.$emit('edit')
        }

      }
    }

  },
  data() {
    return {
      conceptConst,
      tableConst,
      loading: false,
      /*控制编辑页面各个模块表单的显示与隐藏*/
      formDisplay: {},
      /*复制新增药品标记*/
      copyEditFlag: false,
      /*药品信息*/
      dkbDp: {
        /*原研药标记0否1是2未知*/
        originalDrug: 2,
        /*一致性评价标记0否1是2未知*/
        consistencyEvaluation: 2,
        /*国产进口标识*/
        domesticOrImport: undefined,
        /*通用名*/
        genericName: '',
        /* 商品名*/
        tradeName: '',
        /*英文名*/
        englishName: '',
        /*制剂id*/
        preparationId: undefined,
        /*制剂+规格描述*/
        preparationDesc: '',
        /*制剂规格id*/
        specId: undefined,
        /*上市许可持有人id*/
        mahId: undefined,
        /*上市许可持有人名称*/
        mahName: '',
        /*药品名称*/
        dkbDpAliasList: [],
        aliasPrimary: 0,
        /*药品批准文号*/
        dkbDpApprovalNumList: [],
        // 本位码
        dkbDpStandardCodeList:[],
        //生产企业
        dkbDpManufacturerList:[],
        /*药品规格描述*/
        dkbDpSpecDescList: [],
        /*药品成分*/
        dkbDpSpecIngredientList: [],
        /* 包装药品*/
        dkbPdProdList: [],
        /* 关联的制剂信息*/
        dkbPpInfo: {}

      },
      /* 标志数据是否编辑过*/
      editFlag: false,
      /* 数据编辑次数*/
      editTimes: 0,
      /*制剂规格检索相关*/
      preparation: {
        /* 输入框值*/
        searchValue: '',
        /*控制弹窗显示隐藏*/
        dialogVisible: false,
        /*制剂规格表格数据*/
        tableData: [],
        pageNum: 1,
        pageSize: 10,
        /*总条数*/
        total: 0,
        orderByColumn: 'preparationName',
        isAsc: 'desc'
      },
      /*企业类型下拉列表*/
      manufacturerTypeDict:[],
      /*名称类型下拉列表*/
      aliasTypeConcept: [],
      /*语言概念下拉列表*/
      languageConcep: [],
      /*国产进口标识下拉列表*/
      domesticOrImportDict: [],
      /*药物成分类型字典*/
      drugIngredientTypeDict: [],
      /*数量类型字典*/
      quantityTypeDict: [],
      /*数量单位字典*/
      quantityUnitDict: [],
      quantityUnitDictOptions: [],
      /*包装数量单位 盒  瓶  包*/
      packQtyUnitDict: [],
      packQtyUnitDictOptions: [],
      /*时间单位字典*/
      timeUnitDict: [],
      /*厂商下拉列表数据*/
      manufacturerList: [],
      /*添加药品成分弹窗*/
      addSpecIngredientDialogVisible: false,
      /*添加/修改药品成分表单*/
      specIngredientForm: {},
      /*添加/修改药品成分表单校验规则*/
      specIngredientFormRule: {
        substanceId: [{ required: true, message: '请选择成分物质', trigger: 'blur' }],
        quantityType: [],
        denominatorQty: [],
        denominatorQtyUnitId: []
      },
      specIngredientFormRuleDefault: {
        substanceId: [{ required: true, message: '请选择成分物质', trigger: 'blur' }],
        quantityType: [{ required: true, message: '请选择数量类型', trigger: 'blur' }],
        denominatorQty: [{ required: true, message: '请输入分母数量', trigger: 'blur' }],
        denominatorQtyUnitId: [{ required: true, message: '请选择分母数量单位', trigger: 'blur' }]
      },
      /*修改成分弹窗--名称下拉列表数据*/
      substanceList: [],
      /*添加/编辑包装药品弹窗*/
      editPdProdDialogVisible: false,
      /*添加/编辑包装药品-表单*/
      pdProdForm: {},
      /*包装明细拆分弹窗*/
      editPdSpecDialogVisible: false,
      /*包装药品拆分明细表单*/
      pdSpecForm: {},
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行*/
      editTableRowIndex: undefined,
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      isDrugProd: false,
      isTruePzwh: false,
      /*本位码选择弹窗*/
      standardCodeSelectDialogVisible: false,
      cfdaDrugList: [],
      flag: 0,
      menulist: [
        {
          href: '#a01',
          name: '药品及制剂规格'
        },
        {
          href: '#a02',
          name: '药品成分(包含成分)'
        },
        {
          href: '#a03',
          name: '包装药品'
        }
      ],
      /*包装材料明细表格弹窗显示隐藏*/
      pdProdMatListDialogVisible: false,
      /*包装材料表格数据*/
      pdProdMatList: [],
      /*包装材料表格数据所属的包装药品表格行index*/
      pdProdMatListIndex: undefined,
      /*编辑包装材料弹窗显示隐藏*/
      editPdProdMatDialogVisible: false,
      /*编辑包装材料表单*/
      editPdProdMatForm: {},
      /*国家医保药品目录和上海医保药品目录字典表格数据*/
      dictDrugTableData: [],
      dictDrugForm: {
        drugName: '',
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      /*药品字典表格弹框显示隐藏*/
      nhsaDrugDictDialogVisible:false,
      smpaDrugDictDialogVisible:false,
      nowEditInputIndex:undefined,
      drugDictType: '',
      /*列表页面查询参数*/
      queryParams: {},
      /*存储根据查询获取到的数据，用于翻页*/
      pageData: [],
     /* 根据本位码和批准文号关联查询的厂商列表*/
      relationManufacturerList:[],
      /*批准文号查询弹窗相关数据*/
      approvalNumDialog:{
        inputIndex:0,
        visible:false,
        tableData:[],
        total:0,
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          drugName: '',
          manufacturer:'',
          orderByColumn:undefined,
          isAsc:undefined,
        },
      },
      //医保药品备注信息编辑弹窗
      editDrugCommentDialogVisible:false,
      //医保药品备注信息编辑表单
      drugCommentForm:{
        drugId:"",
        comment:"",
        drugType:""
      },


    }
  },
  mounted: function() {
  },
  created() {
    this.loading = true
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })

    if (!this.formDisplayData) {
      this.formDisplay = {
        base: true,
        alias: true,
        approvalNum: true,
        specDes: true,
        specIngredient: true,
        pdProd: true
      }
    }
    Object.assign(this.formDisplay, this.formDisplayData)
    if (this.dkbDpData) {
      //dkbDpData有值，代表是通过组件调用传递过来的，是弹窗显示
      this.init().then(result => {
        this.initDkbDpInfo(this.dkbDpData)

        //Object.assign(this.dkbDp, this.dkbDpData)
        //this.dkbDp=this.dkbDpData
        //this.dkbDp.aliasPrimary = this.dkbDpData.aliasPrimary
      })

    } else {
      // 直接打开的新增、编辑页面
      const id = this.$route.params && this.$route.params.id
      //从制剂规格跳转过来新增药品会带上specId
      const ppSpecId = this.$route.params && this.$route.params.specId
      const path = this.$route.path
      if (path && path.indexOf('copyEdit') >= 0) {
        this.copyEditFlag = true
      }

      if (id) {
        this.queryParams = getCache('drugProdQueryParams')
        this.pageData = getCache('drugProdPageData')
        if (!this.queryParams) {
          this.queryParams = {}
        }
        if (!this.queryParams.pageNum) {
          this.queryParams.pageNum = 1
        }
        if (!this.queryParams.pageSize) {
          this.queryParams.pageSize = 10
        }
        this.init().then(result => {
          this.getDkbDpInfo(id)
        })
      } else {
        this.init().then(result => {
          this.addAlias('中文')
          this.addApprovalNum('')
          this.addStandardCode('')
          this.addManufacturer('')
          this.addSpecDesc()
          //国产进口标识，设置默认值
          if (this.domesticOrImportDict && this.domesticOrImportDict.length > 0) {
            this.dkbDp.domesticOrImport = this.domesticOrImportDict[0].conceptId
          }
          //从制剂规格跳转过来新增药品
          if (path && path.indexOf('addByPpSpecId') >= 0 && ppSpecId) {
            //查询制剂规格信息
            querySpecList({ pageNum: 1, pageSize: 2, specId: ppSpecId }).then(response => {
              if (response.total > 0) {
                this.choosePreparation(0, response.rows[0])
              }
            })
          }
          this.loading = false
        })

      }
    }
    this.scrollFn()

  },
  computed: {

    /* 计算属性--药品成分表格高度*/
    specIngredientTableHeight() {
      let dkbDpSpecIngredientList = this.dkbDp.dkbDpSpecIngredientList
      let row = dkbDpSpecIngredientList ? dkbDpSpecIngredientList.length : 0
      // let height=0;
      // height=150+(row-1)*50
      // if(height<150){
      //   return 150
      // }else if(height>400){
      //   return 400
      // }else{
      //   return height
      // }
      let height = 0
      height = 100 + (row - 1) * 50
      if (height < 250) {
        return 250
      } else if (height > 400) {
        return 400
      } else {
        return height
      }
    },

    /* 计算属性--包装药品表格高度*/
    pdProdTableHeight() {
      let dkbPdProdList = this.dkbDp.dkbPdProdList
      let row = dkbPdProdList ? dkbPdProdList.length : 0
      let height = 0
      height = 300 + (row - 1) * 50
      if (height < 300) {
        return 300
      } else if (height > 400) {
        return 400
      } else {
        return height
      }
    }
  },
  methods: {
    chooseMenu(index) {
      this.flag = index
    },
    scrollFn() {
      $(document).ready(function() {
        // var navHeight = $(".inside-menu-wrap").offset().top;
        var navHeight = $('.inside-menu-wrap').offset().top
        var navFix = $('.inside-menu-wrap')
        $(window).scroll(function() {
          if ($(this).scrollTop() >= navHeight) {
            navFix.addClass('menuFix')

          } else {
            navFix.removeClass('menuFix')
            $('.inside-menu li a.active').removeClass('active')
          }
        })
      })

      //内容信息导航锚点
      $('.inside-menu-wrap').navScroll({
        scrollSpy: true
      })
    },
    // 批准文号输入判断
    pzwhInput(val, index) {
      if (this.dkbDp.domesticOrImport === 108601) {
        this.pzwhgcChoice(val, index)
      } else if (this.dkbDp.domesticOrImport === 108602) {
        this.pzwhjkChoice(val, index)
      }
      // 重新渲染数据
      this.makeCardDDataListCopy = DEEP_CLONE(this.dkbDp.dkbDpApprovalNumList)
      this.dkbDp.dkbDpApprovalNumList = []
      this.$nextTick(() => {
        this.dkbDp.dkbDpApprovalNumList = this.makeCardDDataListCopy
      })
    },
    // 批准文号国产正则判断
    pzwhgcChoice(str, index) {
      var strs = str.replace(/(^\s+)|(\s+$)/g, '')//去除前后的空格
      if (!strs.match(/^[\u4e00-\u9fa5]{4}[a-zA-Z]{1,2}[0-9]{8}$/)) {
        console.log('只能输入国药准字 (4个中文) + 1至2位字母 + 8位数字')
        this.dkbDp.dkbDpApprovalNumList[index].isPzwh = true
        this.isTruePzwh = true
        return false
      } else {
        this.dkbDp.dkbDpApprovalNumList[index].isPzwh = false
      }
      // var pattern = /^[\u4e00-\u9fa5]{4}[a-zA-Z]{1}[0-9]{8}$/ // 国产匹配
      // var pattern = /[a-zA-Z]{1,2}[0-9]{8}$/ // 进口匹配
    },
    // 批准文号国产正则判断
    pzwhjkChoice(str, index) {
      var strs = str.replace(/(^\s+)|(\s+$)/g, '')//去除前后的空格
      if (!strs.match(/[a-zA-Z]{1,2}[0-9]{8}$/)) {
        console.log('只能输入可以没有国药准字 + 一位~2位字母 +  8位数字')
        this.dkbDp.dkbDpApprovalNumList[index].isPzwh = true
        this.isTruePzwh = true
        return false
      } else {
        this.dkbDp.dkbDpApprovalNumList[index].isPzwh = false
      }
    },
    /* 成分编辑表单-数量类型--值改变*/
    quantityTypeChange(value) {
      let form = this.$refs['addSpecIngredientForm']
      if (value == undefined || value == '') {
        if (form) {
          this.specIngredientFormRule.denominatorQty = []
          this.specIngredientForm.denominatorQty = undefined
          form.clearValidate('denominatorQty')

          this.specIngredientFormRule.denominatorQtyUnitId = []
          this.specIngredientForm.denominatorQtyUnitId = undefined
          form.clearValidate('denominatorQtyUnitId')
        }
      } else {
        this.specIngredientFormRule.denominatorQty = this.specIngredientFormRuleDefault.denominatorQty
        this.specIngredientFormRule.denominatorQtyUnitId = this.specIngredientFormRuleDefault.denominatorQtyUnitId
      }
    },
    /* 成分编辑表单-分母数量--值改变*/
    denominatorQtyChange(value) {
      let form = this.$refs['addSpecIngredientForm']
      if (value == undefined) {
        if (form) {
          this.specIngredientFormRule.quantityType = []
          this.specIngredientForm.quantityType = undefined
          form.clearValidate('quantityType')

          this.specIngredientFormRule.denominatorQty = []
          this.specIngredientForm.denominatorQty = undefined
          form.clearValidate('denominatorQty')

          this.specIngredientFormRule.denominatorQtyUnitId = []
          this.specIngredientForm.denominatorQtyUnitId = undefined
          form.clearValidate('denominatorQtyUnitId')
        }
      } else {
        this.specIngredientFormRule.quantityType = this.specIngredientFormRuleDefault.quantityType
        this.specIngredientFormRule.denominatorQtyUnitId = this.specIngredientFormRuleDefault.denominatorQtyUnitId
      }
    },
    /*初始化--加载字典等*/
    async init() {
      /*获取语言概念字典*/
      await getConcept(conceptConst.PUB_LANG, 0).then(result => {
        this.languageConcep = result
      })

      /*获取概念进口国产标识字典*/
      await getConcept(conceptConst.DKB_DRUG_IMPORT_CLASS, 0).then(result => {
        this.domesticOrImportDict = result
      })

      /*获取药物成分类型字典*/
      await getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
        this.drugIngredientTypeDict = result
      })

      /*获取数量类型字典*/
      await getConcept(conceptConst.PUB_MATH_OPERATOR, 0).then(result => {
        this.quantityTypeDict = result
      })

      /*获取质量单位字典*/
      await getConcept(conceptConst.PUB_MASS_UNIT, 0).then(result => {
        this.quantityUnitDict = result
        this.quantityUnitDictOptions = result
      })

      /*获取有效期时间单位字典*/
      await getConcept(conceptConst.PUB_TIME_UNIT_EXPIRATION, 0).then(result => {
        this.timeUnitDict = result
      })

      /**规格包装单位概念域（片、粒、袋、支、瓶、盒等）*/
      await getConcept(conceptConst.PUB_SPEC_PKG_UNIT, 0).then(result => {
        this.packQtyUnitDict = result
        this.packQtyUnitDictOptions = result
      })

      /*获取名称类型字典*/
      await getConcept(conceptConst.PUB_NAME_TYPE, 0).then(result => {
        this.aliasTypeConcept = result
      })

      /*获取企业类型类型字典*/
      await getConcept(conceptConst.PUB_MANUFACTURER_TYPE, 0).then(result => {
        this.manufacturerTypeDict = result
      })
    },
    /*添加药品名称*/
    addAlias(type) {
      let aliasList = this.dkbDp.dkbDpAliasList
      let defaultVal
      if (this.languageConcep && this.languageConcep.length > 0) {
        this.languageConcep.forEach(item => {
          if (item.conceptVal == type) {
            defaultVal = item.conceptId
          }
        })
      }

      aliasList.push({
        alias: '',
        aliasType: type=='英语'?conceptConst.PUB_NAME_TYPE_EN:conceptConst.PUB_NAME_TYPE_GENERIC,
        aliasLangue: defaultVal,
        isPrimary: aliasList.length == 0 ? '1' : '0'
      })
      if (aliasList.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.dkbDp.aliasPrimary = 0
      }
    },
    /*删除药品名称*/
    delAlias(item) {
      let dkbDpAliasList = this.dkbDp.dkbDpAliasList
      var index = dkbDpAliasList.indexOf(item)
      if (dkbDpAliasList.length > 1) {
        dkbDpAliasList.splice(index, 1)
      } else {
        this.$message('药品名称至少保留一个！')
      }

      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      dkbDpAliasList.forEach(function(item, index) {
        if (item.isPrimary === '1') {
          primary = index
          return
        }
      })
      if (primary === -1) {
        //删除后没有默认值了
        dkbDpAliasList[0].isPrimary = '1'
        this.dkbDp.aliasPrimary = 0
      }
    },
    /*添加批准文号*/
    addApprovalNum(approvalNum) {
      let dkbDpApprovalNumList = this.dkbDp.dkbDpApprovalNumList
      dkbDpApprovalNumList.push({
        approvalNum: isEmpty(approvalNum) ? '国药准字' : approvalNum
      })
    },
    /*添加生产企业*/
    addManufacturer(manufacturerId) {
      let dkbDpManufacturerList = this.dkbDp.dkbDpManufacturerList
      dkbDpManufacturerList.push({
        manufacturerId:undefined,
        manufacturerName:'',
        manufacturerType:this.manufacturerTypeDict[0]?.conceptId
      })
    },
    /* 删除批准文号*/
    delApprovalNum(item) {
      let dkbDpApprovalNumList = this.dkbDp.dkbDpApprovalNumList
      if (dkbDpApprovalNumList.length == 1) {
        this.$message('批准文号至少保留一个！')
      } else {
        var index = dkbDpApprovalNumList.indexOf(item)
        dkbDpApprovalNumList.splice(index, 1)
      }
    },
    /*添加本位码*/
    addStandardCode(standardCode) {
      let dkbDpStandardCodeList = this.dkbDp.dkbDpStandardCodeList
      dkbDpStandardCodeList.push({
        standardCode: standardCode
      })
    },
    /* 删除本位码*/
    delStandardCode(item) {
      let dkbDpStandardCodeList = this.dkbDp.dkbDpStandardCodeList
      if (dkbDpStandardCodeList.length == 1) {
        this.$message('本位码至少保留一个！')
      } else {
        var index = dkbDpStandardCodeList.indexOf(item)
        dkbDpStandardCodeList.splice(index, 1)
      }
    },
    /* 删除生产企业*/
    delManufacturer(item) {
      let dkbDpManufacturerList = this.dkbDp.dkbDpManufacturerList
      if (dkbDpManufacturerList.length == 1) {
        this.$message('生产企业至少保留一个！')
      } else {
        var index = dkbDpManufacturerList.indexOf(item)
        dkbDpManufacturerList.splice(index, 1)
      }
    },
    /*删除规格描述*/
    delSpecDesc(item) {
      let dkbDpSpecDescList = this.dkbDp.dkbDpSpecDescList
      if (dkbDpSpecDescList.length == 1) {
        this.$message('规格描述至少保留一个！')
      } else {
        var index = dkbDpSpecDescList.indexOf(item)
        dkbDpSpecDescList.splice(index, 1)
      }
    },
    /*添加规格描述*/
    addSpecDesc() {
      let dkbDpSpecDescList = this.dkbDp.dkbDpSpecDescList
      dkbDpSpecDescList.push({
        specDesc: ''
      })
    },
    /*获取药品详细信息*/
    getDkbDpInfo(id) {
      info(id).then(response => {
        if (!response.data) {
          this.msgError('获取药品信息失败')
          return
        }
        this.initDkbDpInfo(response.data)
      })
    },
    initDkbDpInfo(info) {
      // 对象属性拷贝  TODO 可能有问题，要检查
      Object.assign(this.dkbDp, info)
      if (this.copyEditFlag) {
        //复制新增药品，清除id属性
        this.clearId()
      }
      if (info.dkbPpInfo) {
        let dkbPpInfo = info.dkbPpInfo
        this.dkbDp.preparationDesc = dkbPpInfo.preparationName + '（' + (dkbPpInfo.preparationForm || '') + '）' + '（' + (dkbPpInfo.preparationSpecDesc || '') + '）'
      }
      // 处理别名首选项 aliasPrimary
      if (this.dkbDp.dkbDpAliasList) {
        let aliasList = this.dkbDp.dkbDpAliasList
        let dkbDp = this.dkbDp
        aliasList.forEach(function(item, index) {
          if (item.isPrimary === '1') {
            dkbDp.aliasPrimary = index
          }
        })
      }
      this.loading = false
    },
    /*清除id属性值，复制新增药品时候使用*/
    clearId() {
      this.dkbDp.id = undefined
      // 处理别名
      if (this.dkbDp.dkbDpAliasList) {
        this.dkbDp.dkbDpAliasList.forEach(function(item) {
          item.id = undefined
          item.drugProdId = undefined
        })
      }
      // 处理批准文号
      if (this.dkbDp.dkbDpApprovalNumList) {
        this.dkbDp.dkbDpApprovalNumList.forEach(function(item) {
          item.id = undefined
          item.drugProdId = undefined
        })
      }

      // 处理规格描述
      if (this.dkbDp.dkbDpSpecDescList) {
        this.dkbDp.dkbDpSpecDescList.forEach(function(item) {
          item.id = undefined
          item.drugProdId = undefined
        })
      }

      // 处理药品成分
      if (this.dkbDp.dkbDpSpecIngredientList) {
        this.dkbDp.dkbDpSpecIngredientList.forEach(function(item) {
          item.id = undefined
          item.drugProdId = undefined
        })
      }
      // 清除包装药品信息
      this.dkbDp.dkbPdProdList = []
    },
    /*首选项单选按钮变化事件*/
    primaryChange(value, type, index) {
      let field
      if (type === 'aliasPrimary') {
        field = 'dkbDpAliasList'
      }
      this.dkbDp[type] = index
      this.dkbDp[field].forEach(function(item, index) {
        if (index === value) {
          item.isPrimary = '1'
        } else {
          item.isPrimary = '0'
        }
      })
    },
    /*远程搜索厂商信息*/
    async queryManufacturer(query) {
      if (!isEmpty(query)) {
        let res = await selectManufacturerNameByKey(query.trim())
        if (res.code === 200) {
          this.manufacturerList = res.data
        }
      } else {
        this.manufacturerList = []
      }
    },
    /*制剂检索*/
    queryPreparation() {
      this.isDrugProd = true
      this.loading = true
      this.preparation.dialogVisible = true
      let params = {
        searchValue: this.preparation.searchValue,
        pageNum: this.preparation.pageNum,
        pageSize: this.preparation.pageSize,
        orderByColumn: this.preparation.orderByColumn,
        isAsc: this.preparation.isAsc
      }
      querySpecList(params).then(response => {
        this.preparation.tableData = response.rows
        this.preparation.total = response.total
        this.loading = false
        this.loading = false
      })
    },
    /*制剂检索表格，选中数据*/
    choosePreparation(index, row) {
      this.dkbDp.dkbPpInfo = row
      this.dkbDp.preparationId = row.preparationId
      this.dkbDp.specId = row.preparationSpecId
      this.dkbDp.preparationDesc = row.preparationName + '（' + (row.preparationForm || '') + '）' + '（' + (row.preparationSpecDesc || '') + '）'
      this.preparation.dialogVisible = false
      if (this.dkbDp.id == undefined) {
        this.autoSetSpecIngredient(row.preparationSpecId)
      }
      let specDescItem=this.dkbDp.dkbDpSpecDescList[0]
        if(specDescItem){
          specDescItem.specDesc=row.preparationSpecDesc
        }

      //调用接口校验，本位码+制剂规格id是否重复
      this.doCheckStandardCodeAndPpSpecId()


    },
    /*自动设置药品成分*/
    autoSetSpecIngredient(specId) {
      //新增时自动填充药品成分
      this.dkbDp.dkbDpSpecIngredientList = []
      let that = this
      getRecommendSpecIngredient(specId).then(response => {
        if (response.data && response.data.length > 0) {
          response.data.forEach(function(item) {
            that.dkbDp.dkbDpSpecIngredientList.push(item)
          })
        }
      })
    },
    /* 删除药品成分*/
    delSpecIngredient(index, row) {
      let specIngredientList = this.dkbDp.dkbDpSpecIngredientList
      if (specIngredientList.length == 1) {
        this.$message('药品成分至少保留一个！')
      } else {
        specIngredientList.splice(index, 1)
      }
    },
    /*新增/编辑药品成分*/
    editSpecIngredient(index, row) {
      this.initSpecIngredientForm()
      let form = this.$refs['addSpecIngredientForm']
      if (form) {
        form.clearValidate()
      }
      this.substanceList = []
      if (row != undefined) {
        //编辑成分
        Object.assign(this.specIngredientForm, row)
        this.specIngredientForm.index = index
        this.substanceList.push({ substanceId: row.substanceId, name: row.substanceName })
      } else {
        //新增成分
        // 成分类型
        if (this.drugIngredientTypeDict && this.drugIngredientTypeDict.length > 0) {
          this.specIngredientForm.ingredientType = this.drugIngredientTypeDict[0].conceptId
          this.specIngredientForm.ingredientTypeDesc = this.drugIngredientTypeDict[0].conceptVal
        }
      }
      this.addSpecIngredientDialogVisible = true
    },
    /*成分编辑弹窗-取消按钮*/
    cancelSpecIngredient() {
      this.addSpecIngredientDialogVisible = false
    },
    /*成分编辑弹窗-确定按钮*/
    confirmSpecIngredient() {

      this.$refs['addSpecIngredientForm'].validate(valid => {
        if (valid) {
          //this.msgSuccess('成分编辑表单校验完成')
          let form = this.specIngredientForm
          //处理字典项-物质名称
          this.substanceList.forEach(function(item) {
            if (item.substanceId == form.substanceId) {
              form.substanceName = item.name
              return
            }
          })

          //处理字典项-数量类型
          form.quantityTypeDesc = this.getConceptVal(this.quantityTypeDict, form.quantityType)
          //处理字典项-物质数量单位
          form.substanceQtyUnitDesc = this.getConceptVal(this.quantityUnitDict, form.substanceQtyUnitId)
          //处理字典项-分母数量单位
          form.denominatorQtyUnitDesc = this.getConceptVal(this.quantityUnitDict, form.denominatorQtyUnitId)
          //处理字典项-成分类型
          form.ingredientTypeDesc = this.getConceptVal(this.drugIngredientTypeDict, form.ingredientType)

          let index = this.specIngredientForm.index
          if (index != undefined) {
            //编辑成分
            Object.assign(this.dkbDp.dkbDpSpecIngredientList[index], this.specIngredientForm)
          } else {
            //新增成分
            this.dkbDp.dkbDpSpecIngredientList.push(this.specIngredientForm)
            this.specIngredientForm = {}
          }
          this.addSpecIngredientDialogVisible = false
        } else {
          this.msgError('请规范填写成分编辑')
        }
      })

    },
    /*初始化药品成分编辑弹窗表单字段*/
    initSpecIngredientForm() {
      this.specIngredientForm = {
        /*当前编辑表单对应的表格行index*/
        index: undefined,
        /*物质id*/
        substanceId: undefined,
        /*物质名称*/
        substanceName: '',
        ingredientType: undefined,
        ingredientTypeDesc: '',
        quantityType: undefined,
        quantityTypeDesc: '',
        denominatorQty: undefined,
        denominatorQtyUnitId: undefined,
        denominatorQtyUnitDesc: '',
        substanceQtyMin: undefined,
        substanceQtyMax: undefined,
        substanceQtyUnitId: undefined,
        substanceQtyUnitDesc: '',
        substanceReferenceDesc: '',
        dkbPdSpecList: []
      }
    },
    /*添加药品成分弹窗--物质名称检索*/
    async querySubstanceName(query) {
      if (!isEmpty(query)) {
        this.loading = true
        let res = await selectSubstanceNameList(query.trim())
        if (res.code === 200) {
          this.loading = false
          this.substanceList = res.data
        }
      } else {
        this.substanceList = []
      }
    },
    /*根据conceptId从字典中获取概念值的描述*/
    getConceptVal(dict, conceptId) {
      let conceptVal
      dict.forEach(function(item) {
        if (item.conceptId === conceptId) {
          conceptVal = item.conceptVal
        }
      })
      return conceptVal
    },
    /*初始化包装药品编辑弹窗表单字段*/
    initPdProdForm() {
      this.pdProdForm = {
        index: undefined,
        drugName: '',
        packSpecDesc: '',
        packQty: undefined,
        packQtyUnitId: undefined,
        indate: undefined,
        indateUnit: undefined,
        barcode69: '',
        shMedicareNum: '',
        stateMedicareNum: '',
        storage: '',
        dkbPdSpecList: [],
        status: '',
        specUnit: undefined,
        wrapperDesc: '',
        pdProdMatList: []
      }
    },
    /*添加、修改包装药品*/
    editPdProd(index, row) {
      this.initPdProdForm()
      let form = this.$refs['editPdProdForm']
      if (form) {
        form.clearValidate()
      }
      this.editPdProdDialogVisible = true
      if (index == undefined) {
        //添加包装药品
        this.pdProdForm.packType = 1
        this.pdProdForm.packTypeDesc = '独立'
        this.pdProdForm.status = '0'
        if (this.dkbDp.dkbPpInfo) {
          //将所选制剂的规格描述，填充到包装药品的规格描述上
          // 2021-11-22 暂时取消该逻辑
          //this.pdProdForm.packSpecDesc = this.dkbDp.dkbPpInfo.preparationSpecDesc
        }
        // 有效期单位
        if (this.timeUnitDict && this.timeUnitDict.length > 0) {
          this.pdProdForm.indateUnit = this.timeUnitDict[0].conceptId
          this.pdProdForm.indateUnitDesc = this.timeUnitDict[0].conceptVal
        }
        // 数量单位 和包装单位
        if (this.packQtyUnitDict && this.packQtyUnitDict.length > 0) {
          this.pdProdForm.packQtyUnitId = this.packQtyUnitDict[0].conceptId
          this.pdProdForm.packQtyUnitDesc = this.packQtyUnitDict[0].conceptVal
          this.pdProdForm.specUnit = this.pdProdForm.packQtyUnitId
          this.pdProdForm.specUnitDesc = this.pdProdForm.packQtyUnitDesc
        }
        //默认填充通用名到包装药品名称
        this.dkbDp.dkbDpAliasList.forEach(item=>{
          if (item.aliasType == conceptConst.PUB_NAME_TYPE_GENERIC) {
            this.pdProdForm.drugName = item.alias
          }
        })
        if(row){
          //复制包装药品，没有index，只有row
          this.pdProdForm=DEEP_CLONE(row)
        }
      } else {
        //编辑包装药品
       // Object.assign(this.pdProdForm, row)
        this.pdProdForm=DEEP_CLONE(row)
        this.pdProdForm.index = index
      }
    },
    /*复制包装药品*/
    copyPdProd(index, row) {
      let newRow =DEEP_CLONE(this.dkbDp.dkbPdProdList[index])
      newRow.id = undefined
      newRow.dkbPdSpecList = []
      newRow.pdProdMatList=[]
      //Object.assign(newRow.dkbPdSpecList, this.dkbDp.dkbPdProdList[index].dkbPdSpecList)
      /*let sourceSpecList = this.dkbDp.dkbPdProdList[index].dkbPdSpecList
      sourceSpecList.forEach(function(item) {
        item.id = undefined
        let newItem = {}
        Object.assign(newItem, item)
        newRow.dkbPdSpecList.push(newItem)
      })*/

      //this.dkbDp.dkbPdProdList.push(newRow)
      this.editPdProd(undefined,newRow)
      this.msgSuccess('复制完成')

    },
    /*拆分包装药品*/
    editPdSpec(index, row) {
      this.editPdSpecDialogVisible = true
      if(row.dkbPdSpec){
        this.pdSpecForm=deepClone(row.dkbPdSpec)
      }else{
        this.pdSpecForm={}
        this.pdSpecForm.level1PackQtyUnitId=conceptConst.PUB_SPEC_PKG_UNIT_BOARD
      }
      this.pdSpecForm.specUnit=row.specUnit
      this.pdSpecForm.packQtyUnitId=row.packQtyUnitId
      this.pdSpecForm.index = index
    },
    /* 删除包装药品*/
    delPdProd(index, row) {
      let dkbPdProdList = this.dkbDp.dkbPdProdList
      if (dkbPdProdList.length == 1) {
        this.$message('包装药品至少保留一个！')
      } else {
        dkbPdProdList.splice(index, 1)
      }
    },
    /*添加、修改包装药品弹窗-确定按钮*/
    confirmPdProd() {
      this.$refs['editPdProdForm'].validate(valid => {
        if (valid) {
          //this.msgSuccess('表单校验完成')
          let form = this.pdProdForm
          //处理字典项-有效期单位
          //form.indateUnitDesc = this.getConceptVal(this.timeUnitDict, form.indateUnit)
          //处理字典项-包装数量单位
          form.packQtyUnitDesc = this.getConceptVal(this.packQtyUnitDict, form.packQtyUnitId)
          //处理字典项-规格单位
          form.specUnitDesc = this.getConceptVal(this.packQtyUnitDict, form.specUnit)

          //处理有效期单位，有效期没有值时，将有效期单位清空
          /*if (!form.indate) {
            form.indateUnitDesc = undefined
            form.indateUnit = undefined
          }*/

          let index = form.index
          if (index != undefined) {
            //编辑
            //this.dkbDp.dkbPdProdList[index] = form
            Object.assign(this.dkbDp.dkbPdProdList[index], form)
          } else {
            //新增
            this.dkbDp.dkbPdProdList.push(form)
            form = {}
          }
          this.editPdProdDialogVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    /*添加包装药品--取消按钮*/
    cancelPdProd() {
      this.editPdProdDialogVisible = false
    },

    /*包装明细拆分弹窗--删除一行包装明细*/
    delPdSpec(index, row) {
      let dkbPdSpecList = this.pdSpecForm.dkbPdSpecList
      if (dkbPdSpecList.length == 1) {
        this.$message('包装明细至少保留一个！')
      } else {
        dkbPdSpecList.splice(index, 1)
      }
    },
    /*包装明细拆分弹窗--确定按钮*/
    confirmEditPdSpec() {
      this.$refs['editPdSpecForm'].validate(valid => {
        if (valid) {
          let index = this.pdSpecForm.index
          this.dkbDp.dkbPdProdList[index].dkbPdSpec =deepClone(this.pdSpecForm)
          this.editPdSpecDialogVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    /*包装明细拆分弹窗--取消按钮*/
    cancelEditPdSpec() {
      this.editPdSpecDialogVisible = false
    },
    /*返回按钮*/
    handleReturn() {
      // 关闭当前tab页并返回药品与包装列表
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/dkm/drugProd' })
    },
    pzwsChoice() {
      this.dkbDp.dkbDpApprovalNumList.forEach((item, index) => {
        if (this.dkbDp.domesticOrImport === 108601) {
          this.pzwhgcChoice(item.approvalNum, index)
        } else if (this.dkbDp.domesticOrImport === 108602) {
          this.pzwhjkChoice(item.approvalNum, index)
        }
      })
      // 重新渲染数据
      this.makeCardDDataListCopy = DEEP_CLONE(this.dkbDp.dkbDpApprovalNumList)
      this.dkbDp.dkbDpApprovalNumList = []
      this.$nextTick(() => {
        this.dkbDp.dkbDpApprovalNumList = this.makeCardDDataListCopy
      })
    },
    /** 保存按钮--保存药品 */
    submitForm(type) {
      // 保存前校验 制剂规格和本位码组合是否已存在
      let hasFlag2=this.dkbDp.dkbDpStandardCodeList.some(standardCodeItem=>{
        if(standardCodeItem.flag2){
          return true
        }
      })
      if(hasFlag2){
        this.msgError("制剂规格和本位码已存在！")
        return
      }


      this.$refs['form'].validate(async valid => {
        if (valid) {
          await this.pzwsChoice()

          saveOrUpdate(this.dkbDp).then(response => {
            this.msgSuccess(response.msg)
            //调用刷新表格数据的方法
            AcrossUtil.$emit('tableReload');
            this.isTruePzwh = false
            if (type == 2) {
              this.handleReturn()
            }else{
              if(response.data){
                //保存完成后根据id跳转到编辑界面
                this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
                  //this.$router.push('/drugProd/edit/' + response.data)
                  this.$nextTick(() => {
                    this.$router.replace({
                      path: '/redirect' + '/drugProd/edit/' + response.data
                    })
                  })
                })
              }
            }

          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*保存别名*/
    submitAliasForm() {
      if (this.editFlag == false) {
        this.msgInfo('数据没有改变，无需保存')
        return
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          let formData = {}
          formData.id = this.dkbDp.id
          formData.dkbDpAliasList = this.dkbDp.dkbDpAliasList
          formData.updateType = 'dkbDpAliasList'
          saveOrUpdate(formData).then(response => {
            this.msgSuccess(response.msg)
            // 通知父组件
            this.$emit('editFinish', 'dkbDpAliasList')
          })
        } else {
          this.msgError('请规范填写别名信息')
        }
      })
    },
    /*保存批准文号*/
    submitApprovalNumForm() {
      if (this.editFlag == false) {
        this.msgInfo('数据没有改变，无需保存')
        return
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          let formData = {}
          formData.id = this.dkbDp.id
          formData.dkbDpApprovalNumList = this.dkbDp.dkbDpApprovalNumList
          formData.updateType = 'dkbDpApprovalNumList'
          saveOrUpdate(formData).then(response => {
            this.msgSuccess(response.msg)
            this.$emit('editFinish', 'dkbDpApprovalNumList')
          })
        } else {
          this.msgError('请规范填写批准文号信息')
        }
      })
    },
    /*保存药品成分*/
    submitSpecIngredientForm() {
      if (this.editFlag == false) {
        this.msgInfo('数据没有改变，无需保存')
        return
      }
      let formData = {}
      formData.id = this.dkbDp.id
      formData.dkbDpSpecIngredientList = this.dkbDp.dkbDpSpecIngredientList
      formData.updateType = 'dkbDpSpecIngredientList'
      saveOrUpdate(formData).then(response => {
        this.msgSuccess(response.msg)
        this.$emit('editFinish', 'dkbDpSpecIngredientList')
      })
    },
    /*保存包装药品*/
    submitPdProdForm() {
      if (this.editFlag == false) {
        this.msgInfo('数据没有改变，无需保存')
        return
      }
      let formData = {}
      formData.id = this.dkbDp.id
      formData.dkbPdProdList = this.dkbDp.dkbPdProdList
      formData.updateType = 'dkbPdProdList'
      saveOrUpdate(formData).then(response => {
        this.msgSuccess(response.msg)
        this.$emit('editFinish', 'dkbPdProdList')
      })
    },
    /*新增制剂*/
    addPreparation() {
      this.preparation.dialogVisible = false
      this.$router.push('/preparationSpecification/edit')
    },

    /* 批准文号-重复性校验*/
    validateApprovalNum(rule, value, callback, index) {
      if (!isEmpty(value)) {
        if (value.trim() === '国药准字') {
          callback(new Error('请输入批准文号'))
        }
        // 页面表单校验，
        let approvalNumList = this.dkbDp.dkbDpApprovalNumList
        let key = Object.keys(index)[0]
        let nowIndex = key.replaceAll('dkbDpApprovalNumList.', '').replaceAll('.approvalNum', '')
        if (approvalNumList) {
          approvalNumList.forEach(function(item, i) {
            if (i != nowIndex && item.approvalNum && item.approvalNum.trim() === value.trim()) {
              callback(new Error('批准文号已存在,请勿重复添加'))
            }
          })
        }
        callback()
      } else {
        callback()
      }
    },
    /* 物质数量单位--下拉搜索过滤*/
    substanceQtyUnitFilter(query) {
      if (query !== '') {
        this.quantityUnitDictOptions = this.quantityUnitDict.filter(item => {
          let flag = item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if (item.aliasList) {
            item.aliasList.some(aliasItem => {
              if ((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase()) > -1)
              ) {
                flag = true
                return true
              }
            })
          }
          return flag
        })

      } else {
        this.quantityUnitDictOptions = this.quantityUnitDict
      }
    },
    /* 包装数量单位--下拉搜索过滤*/
    packQtyUnitFilter(query) {
      if (query !== '') {
        this.packQtyUnitDictOptions = this.packQtyUnitDict.filter(item => {
          let flag = item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if (item.aliasList) {
            item.aliasList.some(aliasItem => {
              if ((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase()) > -1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase()) > -1)
              ) {
                flag = true
                return true
              }
            })
          }
          return flag
        })

      } else {
        this.packQtyUnitDictOptions = this.packQtyUnitDict
      }
    },
    /*复制制剂名称*/
    copyName(index) {
      if (!this.dkbDp.preparationId) {
        this.$message('请先选择制剂规格！')
        return
      }
      let dkbDpAliasList = this.dkbDp.dkbDpAliasList
      let dkbPpInfo = this.dkbDp.dkbPpInfo
      getAliasInfo(dkbPpInfo.preparationId).then(response => {
        if (response.data && response.data.length > 0) {
          let aliasList = response.data
          let newAliasList = []
          aliasList.forEach(item => {
            if (item.aliasType == conceptConst.PUB_NAME_TYPE_EN || item.aliasType == conceptConst.PUB_NAME_TYPE_GENERIC) {
              newAliasList.push(item)
            }
          })

          if (newAliasList.length > 0) {
            if (isEmpty(dkbDpAliasList[index].alias)) {
              dkbDpAliasList.splice(index, 1)
            }
            //记录当前药品别名是否已有首选项
            let  hasPrimary=false
            dkbDpAliasList.forEach(item=>{
              if(item.isPrimary=='1'){
                hasPrimary=true
              }
            })
            newAliasList.forEach(item => {
              //如果当前药品别名没有已有首选项，取关联的制剂的别名首选项
              let isPrimary=item.isDefault?'1':'0'
              if(hasPrimary){
                isPrimary='0'
              }
              dkbDpAliasList.push({
                alias: item.preparationAlias,
                aliasLangue: item.languageId,
                aliasType: item.aliasType,
                isPrimary:isPrimary
              })
            })
          } else {
            this.$message('该制剂没有通用名和英文名！')
          }
        }
      })

    },
    /*来源按钮点击*/
    handleResource(index, row) {
      this.resourceTableForm.list = []
      this.editTableRowIndex = index
      Object.assign(this.resourceTableForm.list, row.dkbReferenceInfoList || [])
      let form = this.$refs['resourceTableForm']
      if (form) {
        //还原表单验证
        form.clearValidate()
      }
      this.resourceTableVisible = true

    },
    /*添加一个来源引用*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_PD_PROD,
        sourceTableField: '',
        sourceTableId: this.dkbDp.dkbPdProdList[this.editTableRowIndex].id,
        resourceTypeId: undefined,
        resourceId: undefined
      })
    },
    /* 保存来源引用*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {

          //let list=this.dkbDp.dkbPdProdList[this.editTableRowIndex].dkbReferenceInfoList
          this.$set(this.dkbDp.dkbPdProdList[this.editTableRowIndex], 'dkbReferenceInfoList', this.resourceTableForm.list)
          this.resourceTableVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /* 引用资源名称下拉列表数据值变化*/
    resourceNameChange(value, index) {
      let name
      this.resourceDataOption.forEach((item) => {
        if (item.id == value) {
          name = item.name
        }
      })
      this.resourceTableForm.list[index].resourceName = name
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (!isEmpty(query)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query.trim()).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /*引用资源类型--下拉列表值变化*/
    resourceTypeChange(value, index) {
      this.resourceDataOption = []
      let item = this.resourceTableForm.list[index]
      item.resourceId = undefined
      item.resourceName = undefined

    },
    /*制剂规格表格排序切换*/
    ppSpecTableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.preparation.orderByColumn = prop
      this.preparation.isAsc = order
      this.queryPreparation()

    },
    /*本位码输入框失去焦点触发事件--查询并填充批准文号信息*/
    standardCodeInputBlur(index) {
      let nowStandardCodeItem=this.dkbDp.dkbDpStandardCodeList[index]
      let nowStandardCode=nowStandardCodeItem.standardCode

      if (isEmpty(nowStandardCode)) {
        nowStandardCodeItem.flag1=false
        nowStandardCodeItem.flag1DrugProdId=''
        nowStandardCodeItem.flag2=false
        nowStandardCodeItem.flag2DrugProdId=''
        this.$forceUpdate()
        return
      }
      //根据本位码查询cfda药品目录
      selectByStandardCode(nowStandardCode.trim()).then(response => {
        if (response.data && response.data.length > 0) {
          let cfdaDrugList = response.data
          this.cfdaDrugList = response.data
          cfdaDrugList.forEach(cfdaDrug => {
            if (this.dkbDp.dkbDpApprovalNumList.length > 0) {
              let find = false
              this.dkbDp.dkbDpApprovalNumList.some(item => {
                if (item.approvalNum === cfdaDrug.approvalNum) {
                  find = true
                  return true
                }
              })
              if (!find) {
                this.addApprovalNum(cfdaDrug.approvalNum)
              }
            } else {
              this.addApprovalNum(cfdaDrug.approvalNum)
            }
          })

        }
      })

      // 调用接口校验，本位码是否重复
      let dkbDpStandardCodeItem=this.dkbDp.dkbDpStandardCodeList[index]
      checkFields('standard_code',nowStandardCode.trim(), this.dkbDp.id).then(response => {
        if (response.data && response.data.drugProdId) {
          dkbDpStandardCodeItem.flag1DrugProdId=response.data.drugProdId
          dkbDpStandardCodeItem.flag1=true
        }else{
          dkbDpStandardCodeItem.flag1DrugProdId=''
          dkbDpStandardCodeItem.flag1=false
        }
        this.$forceUpdate()
      })
      this.doCheckStandardCodeAndPpSpecId()


    },
    // 调用接口校验，本位码+制剂规格id是否重复
    doCheckStandardCodeAndPpSpecId(){
      let dkbDpStandardCodeList=this.dkbDp.dkbDpStandardCodeList
      if(dkbDpStandardCodeList){
        dkbDpStandardCodeList.forEach((standardCodeItem,index) =>{
          if(!isEmpty(standardCodeItem.standardCode) && !isEmpty(this.dkbDp.specId)){
            checkStandardCodeAndPpSpecId(standardCodeItem.standardCode.trim(), this.dkbDp.specId, this.dkbDp.id).then(response => {
              if (response.data && response.data.id) {
                standardCodeItem.flag2DrugProdId=response.data.id
                standardCodeItem.flag2=true
              }else{
                standardCodeItem.flag2DrugProdId=''
                standardCodeItem.flag2=false
              }
              this.$forceUpdate()
            })
          }
        })
      }

    },
    /*批准文号输入框值改变触发事件*/
    approvalNumInputChange(approvalNum, index) {
      //根据批准文号填充国产进口标志
      this.fillDomesticOrImport()
      //根据批准文号查询并填充本位码信息
      this.fillStandardCode()
      //批准文号规范性校验
      this.pzwhInput(approvalNum, index)

    },
    /*批准文号输入框值改变触发事件--查询并填充本位码信息*/
    fillStandardCode() {
      let approvalNumListStr = ''
      this.dkbDp.dkbDpApprovalNumList.forEach(item => {
        if (!isEmpty(item.approvalNum)) {
          approvalNumListStr += item.approvalNum + ';'
        }
      })
      if(isEmpty(approvalNumListStr)){
        return
      }
      selectByApprovalNumList(approvalNumListStr).then(response => {
        if (response.data && response.data.length == 0) {
          return
        }
        this.cfdaDrugList = response.data
        let dkbDpStandardCodeList=this.dkbDp.dkbDpStandardCodeList
        //只匹配到一个本位码,且当前本位码为空，自动填充
        if (response.data.length == 1 && dkbDpStandardCodeList && dkbDpStandardCodeList[0]
          && isEmpty(dkbDpStandardCodeList[0].standardCode)) {
          dkbDpStandardCodeList[0].standardCode=response.data[0].standardCode
        }else{
          //检查匹配到的本位码是否已填写
          let existNum=0
          this.cfdaDrugList.forEach(cfdaDrug=>{
            dkbDpStandardCodeList.some(standardCodeItem=>{
              if(standardCodeItem.standardCode==cfdaDrug.standardCode){
                //标记当前匹配到的本位码已存在了
                cfdaDrug.existFlag=true
                existNum++
                return true
              }
            })
          })
         if(existNum< this.cfdaDrugList.length){
           //匹配到的本位码 没有全部添加到该药品的本位码列表
           //显示选择本位码弹窗
           this.standardCodeSelectDialogVisible = true
         }else{
         }


        }


        // if (response.data.length == 1) {
        //   //只匹配到一个本位码
        //   if (isEmpty(this.dkbDp.standardCode)) {
        //     this.dkbDp.standardCode = response.data[0].standardCode
        //   } else if (this.dkbDp.standardCode != response.data[0].standardCode) {
        //     //显示选择本位码弹窗
        //     this.standardCodeSelectDialogVisible = true
        //   }
        // } else {
        //   //显示选择本位码弹窗
        //   this.standardCodeSelectDialogVisible = true
        // }
      })
    },
    /*选择本位码弹窗--选择一个本位码事件*/
    selectStandardCode(index) {
      let  item = this.cfdaDrugList[index]
      item.existFlag=true
      this.dkbDp.dkbDpStandardCodeList.push({standardCode:item.standardCode})

    },
    /*查看包装材料表格弹窗*/
    viewPdProdMatList(index, row) {
      this.pdProdMatListDialogVisible = true
      this.pdProdMatList = deepClone(row.pdProdMatList)
      this.pdProdMatListIndex = index
    },
    /*查看包装材料表格弹窗--确定按钮*/
    confirmEditPdProdMatList() {
      let index = this.pdProdMatListIndex
      this.dkbDp.dkbPdProdList[index].pdProdMatList = deepClone(this.pdProdMatList)
      this.pdProdMatListDialogVisible = false
      if(this.dkbDp.id){
        //编辑药品状态,直接保存
        this.submitForm(1)
      }

    },
    /*包装材料明细表格弹窗--添加/编辑包装明细*/
    editPdProdMat(index, row) {
      this.initEditPdProdMatForm()
      if (row) {
        //编辑
        this.editPdProdMatForm=deepClone(row)
        if (this.editPdProdMatForm.indate == null) {
          //解决数字类型字段为null时，前端数字类型输入框显示为0
          this.editPdProdMatForm.indate = undefined
        }
        this.editPdProdMatForm.index = index
      }
      // 编辑时候 默认填充有效期单位为月
      if (this.timeUnitDict && this.timeUnitDict.length > 0 && this.editPdProdMatForm.indateUnit == undefined) {
        //this.editPdProdMatForm.indateUnit = this.timeUnitDict[0].conceptId
        this.editPdProdMatForm.indateUnit = conceptConst.PUB_TIME_UNIT_EXPIRATION_MONTH
      }
      if(this.editPdProdMatForm.stateMedicareNumList.length==0){
        this.editPdProdMatForm.stateMedicareNumList.push("")
      }
      if(this.editPdProdMatForm.shMedicareNumList.length==0){
        this.editPdProdMatForm.shMedicareNumList.push("")
      }
      this.editPdProdMatDialogVisible = true
    },
    /*包装材料明细表格弹窗--删除包装明细*/
    delPdProdMat(index, row) {
      this.pdProdMatList.splice(index, 1)

    },
    /*初始化包装材料编辑表单字段*/
    initEditPdProdMatForm() {
      this.editPdProdMatForm = {
        index: undefined,
        wrapperDesc: '',
        storage: '',
        indate: undefined,
        indateUnit: undefined,
        barcode69: '',
        stateMedicareNum: '',
        stateMedicareNumList: [],
        shMedicareNum: '',
        shMedicareNumList: []
      }
    },
    /*包装材料编辑表单弹窗--确定*/
    confirmPdProdMat() {
      this.$refs['editPdProdMatForm'].validate(valid => {
        if (valid) {
          let formData = deepClone(this.editPdProdMatForm)

          //处理有效期单位，有效期没有值时，将有效期单位清空
          if (!formData.indate) {
            formData.indateUnitDesc = undefined
            formData.indateUnit = undefined
          } else {
            //处理字典项-有效期单位
            formData.indateUnitDesc = this.getConceptVal(this.timeUnitDict, formData.indateUnit)
          }

        if(formData.stateMedicareNumList){
          formData.stateMedicareNum=formData.stateMedicareNumList.toString().replaceAll(",","；")
        }

          if(formData.shMedicareNumList){
            formData.shMedicareNum=formData.shMedicareNumList.toString().replaceAll(",","；")
          }

          if (formData.index != undefined) {
            //编辑
            this.pdProdMatList[formData.index] = formData
          } else {
            //新增
            this.pdProdMatList.push(formData)
          }
          this.editPdProdMatForm = {}
          this.editPdProdMatDialogVisible = false
        } else {
          this.msgError('请规范填写包装材料信息')
        }
      })
    },
    /*查询药品目录*/
    selectDictDrug(type) {
      this.loading = true
      if (type === '国家') {
        nhsaDrugList(this.dictDrugForm).then(response => {
          this.dictDrugTableData = response.rows
          this.dictDrugForm.total = response.total
          this.loading = false
        })
      } else if (type === '上海') {
        smpaDrugList(this.dictDrugForm).then(response => {
          this.dictDrugTableData = response.rows
          this.dictDrugForm.total = response.total
          this.loading = false
        })
      }

    },
    /*药品目录表格排序切换*/
    drugDictTableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.dictDrugForm.orderByColumn = prop
      this.dictDrugForm.isAsc = order
      this.selectDictDrug(this.drugDictType)
    },

    /* 国家医保统编码和上海医保统编码输入框 获得焦点时触发*/
    medicareNumInputFocus(type,index) {
      if(type==='国家'){
        this.nhsaDrugDictDialogVisible=true
        this.smpaDrugDictDialogVisible=false
      }else if(type==='上海'){
        this.smpaDrugDictDialogVisible=true
        this.nhsaDrugDictDialogVisible=false
      }
      this.nowEditInputIndex=index
      this.drugDictType = type
      this.dictDrugForm.drugName = ''
      this.dictDrugForm.orderByColumn = ''
      this.dictDrugForm.isAsc = ''
      this.dictDrugForm.pageNum = 1
      //如果查询内容为空，搜索条件自动填充本位码
      let dkbDpStandardCodeList=this.dkbDp.dkbDpStandardCodeList
      if (dkbDpStandardCodeList && dkbDpStandardCodeList[0]) {
        this.dictDrugForm.drugName =dkbDpStandardCodeList[0].standardCode
      }
      //如果查询内容为空，本位码为空， 搜索条件自动填充批准文号
      if (isEmpty(this.dictDrugForm.drugName)) {
        let approvalNumList = this.dkbDp.dkbDpApprovalNumList
        if (approvalNumList && approvalNumList.length > 0) {
          this.dictDrugForm.drugName = approvalNumList[0].approvalNum
        }
      }
      this.selectDictDrug(this.drugDictType)
    },
    /*药品字典表格--选中一行*/
    async selectDrugDictTableRow(index, row, type) {
      this.smpaDrugDictDialogVisible=false
      this.nhsaDrugDictDialogVisible=false
      let drugType=type==='国家'?1:2
      let drugCode=type==='国家'?row.goodsCode:row.tbdm
      let _this=this
      let checkPass = await this.checkDrugCodeHasRelevance(drugType, drugCode,true)
      if(checkPass==true){
        if(type==='国家'){
          _this.editPdProdMatForm.stateMedicareNumList[index] =drugCode
        }else{
          _this.editPdProdMatForm.shMedicareNumList[index] =drugCode
        }
        //根据国家医保补充上海医保 或 根据上海医保填充国家医保
        _this.autoFillNhsaOrSmpaDrugCode(drugCode,drugType)
      }else{
        if(type==='国家'){
          _this.editPdProdMatForm.stateMedicareNumList[index] =''
        }else{
          _this.editPdProdMatForm.shMedicareNumList[index] =''
        }
      }
      this.$forceUpdate()
    },
    async editNext() {
      if (this.pageData == undefined) {
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res => {
          this.$router.push('/dkm/drugProd')
          return
        })
      }
      let nowIndex = -1
      let id = this.dkbDp.id
      this.pageData.some((item, index) => {
        if (item.id == id) {
          nowIndex = index
          return true
        }
      })
      if (nowIndex > -1 && nowIndex < this.pageData.length - 1) {
        id = this.pageData[nowIndex + 1].id
      } else {
        //查询下一页数据
        this.queryParams.pageNum++
        //向上取整
        let maxPage = Math.ceil(this.queryParams.total / this.queryParams.pageSize)
        if (this.queryParams.pageNum > maxPage) {
          this.msgInfo('已经是最后一条记录了')
          this.queryParams.pageNum--
          return
        }
        let response = await list(this.queryParams)
        this.queryParams.total = response.total
        setCache('drugProdQueryParams', this.queryParams)
        setCache('drugProdPageData', response.rows)
        this.pageData = response.rows
        id = this.pageData[0].id
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res => {
        this.$router.push('/drugProd/edit/' + id)
      })
    },
    async editPrev() {
      if (this.pageData == undefined) {
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res => {
          this.$router.push('/dkm/drugProd')
          return
        })
      }
      let nowIndex = -1
      let id = this.dkbDp.id
      this.pageData.some((item, index) => {
        if (item.id == id) {
          nowIndex = index
          return true
        }
      })
      if (nowIndex > 0) {
        id = this.pageData[nowIndex - 1].id
      } else {
        //查询上一页数据
        this.queryParams.pageNum--
        if (this.queryParams.pageNum < 1) {
          this.msgInfo('已经是第一条记录了')
          this.queryParams.pageNum++
          return
        }
        let response = await list(this.queryParams)
        this.queryParams.total = response.total
        setCache('drugProdQueryParams', this.queryParams)
        setCache('drugProdPageData', response.rows)
        this.pageData = response.rows
        id = this.pageData[response.rows.length - 1].id
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res => {
        this.$router.push('/drugProd/edit/' + id)
      })

    },
    /*复制生产企业名称到上市许可持有人*/
    copyManufacturerMsg() {
      let dkbDpManufacturerList=this.dkbDp.dkbDpManufacturerList
      if(dkbDpManufacturerList && dkbDpManufacturerList[0]){
        this.dkbDp.mahId = dkbDpManufacturerList[0].manufacturerId
        this.dkbDp.mahName = dkbDpManufacturerList[0].manufacturerName
      }

    },
    /* 名称类型-校验*/
    validateAliasType(rule, value, callback, index) {
      if (!isEmpty(value)) {
        let allName = this.dkbDp.dkbDpAliasList
        let englishNameSize = 0
        let genericNameSize = 0
        allName.forEach(function(item, i) {
          if (item.aliasType == conceptConst.PUB_NAME_TYPE_EN) {
            englishNameSize++
          } else if (item.aliasType == conceptConst.PUB_NAME_TYPE_GENERIC) {
            genericNameSize++
          }
        })
        if (englishNameSize > 1) {
          callback(new Error('英文名只能有一个'))
        } else if (genericNameSize > 1) {
          callback(new Error('通用名只能有一个'))
        } else {
          callback()
        }

      } else {
        callback()
      }

    },
   /* 根据本位码和批准文号匹配生产企业*/
    queryRelationManufacturer(){
      this.relationManufacturerList=[]
      let approvalNumListStr=''
      this.dkbDp.dkbDpApprovalNumList.forEach(item=>{
        if(!isEmpty(item.approvalNum)){
          approvalNumListStr+=item.approvalNum.trim()+";"
        }
      })

      let standardCodeListStr=''
      this.dkbDp.dkbDpStandardCodeList.forEach(item=>{
        if(!isEmpty(item.standardCode)){
          standardCodeListStr+=item.standardCode.trim()+";"
        }
      })


      if(isEmpty(standardCodeListStr) && isEmpty(approvalNumListStr) ){
        this.msgInfo('本位码和批准文号为空，无法匹配生产企业!')
        return
      }
      selectRelationManufacturer(approvalNumListStr,standardCodeListStr).then(res=>{
        //relationManufacturerList
        if(res.data.length==0){
          this.msgInfo('根据本位码和批准文号没有匹配到生产企业!')
          return
        }
        this.relationManufacturerList=res.data
      })

    },
    /*选择一个生产厂家*/
    selectRelationManufacturer(row,manufacturerIndex){
      this.manufacturerList=[]
      let manufacturer=this.dkbDp.dkbDpManufacturerList[manufacturerIndex]
      manufacturer.manufacturerId=row.id
      manufacturer.manufacturerName=row.name
      document.getElementById("queryRelationManufacturerBut"+manufacturerIndex).click();
    },
    /*显示批准文号搜索弹窗*/
    showApprovalNumDialog(index){
      this.approvalNumDialog.inputIndex=index
      this.approvalNumDialog.visible=true
      this.approvalNumDialog.queryParams.pageNum=1
      this.approvalNumDialog.queryParams.pageSize=10
      this.approvalNumDialog.queryParams.drugName=''
      this.approvalNumDialog.queryParams.manufacturer=''
      this.approvalNumDialog.queryParams.orderByColumn=''
      this.approvalNumDialog.queryParams.isAsc=''
      this.approvalNumDialog.tableData=[]
      this.approvalNumDialog.total=0
      //默认填充通用名查询
      this.dkbDp.dkbDpAliasList.forEach(item=>{
        if (item.aliasType == conceptConst.PUB_NAME_TYPE_GENERIC) {
          this.approvalNumDialog.queryParams.drugName=item.alias
        }
      })
      this.queryApprovalNum()
    },
    /*批准文号检索*/
    queryApprovalNum(){
      cfdaDrugList(this.approvalNumDialog.queryParams).then(res=>{
        this.approvalNumDialog.tableData=res.rows
        this.approvalNumDialog.total=res.total
      })

    },
    /*批准文号检索表格--排序事件*/
    approvalNumTableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.approvalNumDialog.queryParams.orderByColumn=prop
      this.approvalNumDialog.queryParams.isAsc=order
      this.queryApprovalNum()
    },
    /*选中一个批准文号*/
    chooseApprovalNum(index,row){
      this.approvalNumDialog.visible=false
      this.dkbDp.dkbDpApprovalNumList[this.approvalNumDialog.inputIndex].approvalNum=row.approvalNum
      //手动触发input 的 change
      this.approvalNumInputChange(row.approvalNum, this.approvalNumDialog.inputIndex)
    },
    /*新增药品时，根据批准文号，自动填充国产进口标志*/
    fillDomesticOrImport(){
      //没有国药准字或者以J开头的，为进口，否则为国产。多个批准文号默认为国产
      // 2022-06-06
      // 国产：国药准字H、国药准字Z、国药准字B、国药准字S、国药准字C
      // 进口：H、Z、S、国药准字J、国药准字HJ、国药准字SJ、国药准字ZJ、HC、国药准字HC

      // if(this.dkbDp.id){
      //   return
      // }
      if(this.dkbDp.dkbDpApprovalNumList.length!=1){
        //国产
        this.dkbDp.domesticOrImport=conceptConst.DKB_DRUG_IMPORT_CLASS_DOMESTIC
        return
      }
      let approvalNum=this.dkbDp.dkbDpApprovalNumList[0].approvalNum
      if(approvalNum.trim()==='国药准字'){
        return
      }
      if(approvalNum.toUpperCase().indexOf("J")==0 ||
        approvalNum.toUpperCase().indexOf("H")==0 ||
        approvalNum.toUpperCase().indexOf("Z")==0 ||
        approvalNum.toUpperCase().indexOf("S")==0 ||
        approvalNum.toUpperCase().indexOf("国药准字J")==0 ||
        approvalNum.toUpperCase().indexOf("国药准字HJ")==0 ||
        approvalNum.toUpperCase().indexOf("国药准字SJ")==0 ||
        approvalNum.toUpperCase().indexOf("国药准字ZJ")==0 ||
        approvalNum.toUpperCase().indexOf("HC")==0 ||
        approvalNum.toUpperCase().indexOf("国药准字HC")==0 ||
        approvalNum.toUpperCase().indexOf("国药准字")==-1){
        //进口
        this.dkbDp.domesticOrImport=conceptConst.DKB_DRUG_IMPORT_CLASS_IMPORT
      }else{
        //国产
        this.dkbDp.domesticOrImport=conceptConst.DKB_DRUG_IMPORT_CLASS_DOMESTIC
      }



    },
    /*删除一个医保编码*/
    delMedicareNum(item,index,type){
      if (type === '国家') {
        this.editPdProdMatForm.stateMedicareNumList.splice(index, 1)
      } else if (type === '上海') {
        this.editPdProdMatForm.shMedicareNumList.splice(index, 1)
      }
    },
    /*添加一个医保编码*/
    addMedicareNum(type){
      if (type === '国家') {
        this.editPdProdMatForm.stateMedicareNumList.push("")
      } else if (type === '上海') {
        this.editPdProdMatForm.shMedicareNumList.push("")
      }
    },
    /*国家医保和上海医保输入框校验*/
    validateMedicareNum(rule, value, callback, index){
      //1.单个包装药品的不能重复输入
      //2.非必填项，但是不能空输入框只能保留一个
      let key = Object.keys(index)[0]
      let medicareNumIndex = key.substr(key.indexOf(".")+1)
      let medicareNumList
      if(key.indexOf("stateMedicareNumList")>=0){
        medicareNumList=this.editPdProdMatForm.stateMedicareNumList
      }else{
        medicareNumList=this.editPdProdMatForm.shMedicareNumList
      }
      value=medicareNumList[medicareNumIndex]

      if(medicareNumIndex>0 && isEmpty(value)){
        //检查空值
        callback(new Error('请输入'))
      }else if(!isEmpty(value)){
        //检查重复项
        medicareNumList.forEach((item,i)=>{
          if(item.trim()===value && i!=medicareNumIndex){
            callback(new Error('请勿重复输入'))
          }
        })
        callback()
      }else{
        callback()
      }

    },
    isEmpty(str){
      return isEmpty(str)
    },
    /*快捷搜索医保药品目录*/
    quickSearchDictDrug(e,type){
      this.dictDrugForm.drugName=e.currentTarget.innerText
      this.selectDictDrug(type)
    },
    /* 编辑医保药品备注信息*/
    editDrugComment(row,drugType){
      this.editDrugCommentDialogVisible=true
      this.drugCommentForm.comment=row.comment
      this.drugCommentForm.drugId=row.id
      this.drugCommentForm.drugType=drugType
    },
    /* 保存医保药品备注信息*/
    submitEditDrugComment(){
      let form=this.drugCommentForm
      updateDrugComment(form.drugId,form.comment,form.drugType).then(res => {
        this.editDrugCommentDialogVisible=false
        this.msgSuccess()
        if(form.drugType==1){
          this.selectDictDrug('国家')
        }else{
          this.selectDictDrug('上海')
        }
      })
    },
    //校验包装材料描述是否重复
    validateWrapperDesc(rule, value, callback){
      let matList=this.pdProdMatList
      let nowIndex=this.editPdProdMatForm.index
      if(matList){
        let hasSame= matList.some((matItem,index)=>{
          return index!=nowIndex && matItem.wrapperDesc.trim()==value.trim()
        })
        if(hasSame){
          callback(new Error('包装材料描述已存在'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    },
    //根据国家医保补充上海医保 或 根据上海医保填充国家医保
    async autoFillNhsaOrSmpaDrugCode(drugCode, type) {
      let mappingData
      let sourceData
      if (type ==1) {
        await selectNhsaToSmpaDrugMapping(drugCode, "").then(res=>{
          mappingData=res.data && res.data.length>0?res.data[0].smpaDrugCode:""
        })
        sourceData=this.editPdProdMatForm.shMedicareNumList
      }else if (type ==2) {
        await  selectNhsaToSmpaDrugMapping("", drugCode).then(res=>{
          mappingData=res.data && res.data.length>0?res.data[0].nhsaDrugCode:""
        })
        sourceData=this.editPdProdMatForm.stateMedicareNumList
      }
      if(isEmpty(mappingData)){
        return
      }
      //检查重复
      let hasSame=sourceData.some(t=>{
        return t==mappingData
      })
      if(hasSame){
        return
      }

      //检查关联的国家医保代码或上海医保代码是否已被其他药品关联过
      let checkPass = await this.checkDrugCodeHasRelevance(type==1?2:1, mappingData,false)
      if(!checkPass){
        return
      }

      //没有重复的，添加
      let inserFlag=sourceData.some((item,index)=>{
        if(isEmpty(item)){
          sourceData[index]=mappingData
          return true
        }
      })
      if(!inserFlag){
        sourceData.push(mappingData)
      }
      this.$forceUpdate()
    },
    //检查医保代码是否被关联过
    async checkDrugCodeHasRelevance(type,drugCode,showMsg) {
      let pass=true
      //当前编辑的包装材料index
      let matIndex = this.editPdProdMatForm.index
      //1.本地校验，检查当前没有保存入库的数据
      let errorMsg;
      this.dkbDp.dkbPdProdList.some((drugProd,drugProdIndex)=>{
        //与不是当前包装药品的包装材料比较
        if(drugProdIndex!=this.pdProdMatListIndex){
          drugProd.pdProdMatList.some((matItem, index)=>{
            let codeList
            if (type == 1) {
              //国家医保
              codeList = matItem.stateMedicareNumList
            } else {
              codeList = matItem.shMedicareNumList
            }
            if (codeList.indexOf(drugCode) > -1) {
              errorMsg = "当前医保代码已被当前药品下包装材料为[" + matItem.wrapperDesc + "]的药品关联"
              if(showMsg){
                this.msgInfo(errorMsg)
              }
              pass=false
              return true;
            }
          })
        }else{
          //与当前包装药品的包装材料比较
          this.pdProdMatList.some((matItem, index)=>{
            if (index != matIndex) {
              let codeList
              if (type == 1) {
                //国家医保
                codeList = matItem.stateMedicareNumList
              } else {
                codeList = matItem.shMedicareNumList
              }
              if (codeList.indexOf(drugCode) > -1) {
                errorMsg = "当前医保代码已被当前药品下包装材料为[" + matItem.wrapperDesc + "]的药品关联"
                if(showMsg){
                  this.msgInfo(errorMsg)
                }
                pass=false
                return true;
              }
            }
          })
        }


      })
      // this.dkbPdProd.pdProdMatList.some((matItem, index) => {
      //   if (index != matIndex) {
      //     let codeList
      //     if (type == 1) {
      //       //国家医保
      //       codeList = matItem.stateMedicareNumList
      //     } else {
      //       codeList = matItem.shMedicareNumList
      //     }
      //     if (codeList.indexOf(drugCode) > -1) {
      //       errorMsg = "当前医保代码已被当前药品下包装材料为[" + matItem.wrapperDesc + "]的药品关联"
      //       if(showMsg){
      //         this.msgInfo(errorMsg)
      //       }
      //       pass=false
      //       return true;
      //     }
      //   }
      //
      // })
      if(pass==false){
        return pass
      }

      //2.调用接口远程校验
      let res = await checkDrugHasRelevance(type, drugCode).catch(()=>{
        pass=false
      })
      return pass
    },
    //根据数量和单位信息自动填充包装规格描述
    fillPackSpecDesc(){
      let form=this.pdProdForm
      if(!form.packQty){
        this.msgInfo("请先填写数量")
        return
      }
      //packQty 如果是整数，需要取整
      let packQtyShow=Math.round(form.packQty)
      if(packQtyShow!=form.packQty){
        packQtyShow=form.packQty
      }
      let specUnitDesc
      this.packQtyUnitDictOptions.some(item=>{
        if(item.conceptId==form.specUnit){
          specUnitDesc=item.conceptVal
          return true
        }
      })
      let packQtyUnitDesc
      this.packQtyUnitDictOptions.some(item=>{
        if(item.conceptId==form.packQtyUnitId){
          packQtyUnitDesc=item.conceptVal
          return true
        }
      })
      this.pdProdForm.packSpecDesc=packQtyShow+''+specUnitDesc+'/'+packQtyUnitDesc

    },
    editDrugProd(drugProdId){
      this.$router.push('/drugProd/edit/' + drugProdId)
    },
    //校验生产企业
    validateManufacturer(rule, value, callback, index,thisItem){
      let dkbDpManufacturerList=this.dkbDp.dkbDpManufacturerList
      //限制不能重复输入
      let existFlag=dkbDpManufacturerList.some((manufacturerItem,t)=>{
        if(value==manufacturerItem.manufacturerId
          && thisItem.manufacturerType==manufacturerItem.manufacturerType && t!=index){
          return true
        }
      })

      if(existFlag){
        callback(new Error('厂商不能重复'))
      }else{
        callback()
      }
    },
    //校验本位码,
    validateStandardCode(rule, value, callback, index){
      let dkbDpStandardCodeList=this.dkbDp.dkbDpStandardCodeList
      //限制不能重复输入
      let existFlag=dkbDpStandardCodeList.some((standardCodeItem,t)=>{
        if(value==standardCodeItem.standardCode && t!=index){
          return true
        }
      })
      if(existFlag){
        callback(new Error('请勿重复输入'))
      }else{
        //限制 制剂规格和本位码组合不能重复输入
        if(!isEmpty(value) && !isEmpty(this.dkbDp.specId)){
          checkStandardCodeAndPpSpecId(value.trim(), this.dkbDp.specId, this.dkbDp.id).then(response => {
            let  standardCodeItem=dkbDpStandardCodeList[index]
            if (response.data && response.data.id) {
              standardCodeItem.flag2DrugProdId=response.data.id
              standardCodeItem.flag2=true
              callback(new Error(' '))
            }else{
              standardCodeItem.flag2DrugProdId=''
              standardCodeItem.flag2=false
              callback()
            }
            this.$forceUpdate()
          })
        }else{
          callback()
        }

      }
    }

  }
}
</script>


<style>
/*禁用编辑的输入框文本颜色*/
.el-input.is-disabled .el-input__inner {
  color: #606266 !important;
}

/*输入框后面的搜索按钮*/
.el-input .el-input-group__append:hover {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
/*输入框后面的搜索按钮*/
.el-input .el-input-group__append:active {
  color: #3a8ee6;
  border: 1px solid #3a8ee6 ;
  outline: none;
}
</style>

<style lang="scss" scoped>
/*分类标题*/
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}



/*表格单元格中的下拉列表*/
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

.bottom-bar {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 60px;
  z-index: 200;
  font-size: 12px;
  line-height: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-right: 260px;
}

/*解决表格内部表单元素错位*/
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

.el-form-item {
  margin-bottom: 18px;
}



</style>
