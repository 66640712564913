/**
 *CFDA药品目录
 */

import request from '@/utils/request'


// 查询cfda药品目录
export function list(query) {
  return request({
    url: '/dkm/dict/cfdaDrug/list',
    method: 'post',
    params: query
  })
}


// 根据本位码查询cfda药品目录
export function selectByStandardCode(standardCode) {
  return request({
    url: '/dkm/dict/cfdaDrug/selectByStandardCode',
    method: 'post',
    params: { standardCode: standardCode }
  })
}

// 根据批准文号查询cfda药品目录
export function selectByApprovalNumList(approvalNumListStr) {
  return request({
    url: '/dkm/dict/cfdaDrug/selectByApprovalNumList',
    method: 'post',
    params: { approvalNumListStr: approvalNumListStr }
  })
}
