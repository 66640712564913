var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        overflow: "overlay",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: {
            padding: "20px",
            "flex-grow": "1",
            overflow: "hidden overlay",
          },
        },
        [
          _vm.formDisplay.base
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "inside-menu-wrap", attrs: { span: 24 } },
                    [
                      _c(
                        "ul",
                        { staticClass: "inside-menu" },
                        _vm._l(_vm.menulist, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              class: _vm.flag == index ? "liActive" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.chooseMenu(index)
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: item.href } }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.formDisplay.base
            ? _c(
                "el-col",
                { staticClass: "title-bar", attrs: { span: 24, id: "a01" } },
                [_vm._v("药品及制剂规格")]
              )
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "status-icon": "",
                model: _vm.dkbDp,
                "label-width": "120px",
              },
            },
            [
              _vm.formDisplay.base
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.dkbDp.id,
                                  expression: "dkbDp.id",
                                },
                              ],
                              attrs: { label: "药品ID:" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "300px", float: "left" },
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.dkbDp.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dkbDp, "id", $$v)
                                  },
                                  expression: "dkbDp.id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "制剂规格:",
                                prop: "preparationDesc",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择制剂规格",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px" },
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.dkbDp.preparationDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dkbDp, "preparationDesc", $$v)
                                  },
                                  expression: "dkbDp.preparationDesc",
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-left": "20px",
                                    background: "#2B60F8",
                                  },
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.queryPreparation },
                                },
                                [_vm._v("制剂规格检索 ")]
                              ),
                              _c("el-input", {
                                staticStyle: { display: "none" },
                                attrs: { placeholder: "制剂id" },
                                model: {
                                  value: _vm.dkbDp.preparationId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dkbDp, "preparationId", $$v)
                                  },
                                  expression: "dkbDp.preparationId",
                                },
                              }),
                              _c("el-input", {
                                staticStyle: { display: "none" },
                                attrs: { placeholder: "制剂规格id" },
                                model: {
                                  value: _vm.dkbDp.specId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dkbDp, "specId", $$v)
                                  },
                                  expression: "dkbDp.specId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.base
                ? _c("el-row", [_c("el-col", { attrs: { span: 20 } })], 1)
                : _vm._e(),
              _vm.formDisplay.alias
                ? _c(
                    "el-row",
                    [
                      _vm._l(_vm.dkbDp.dkbDpAliasList, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: "dkbDp-alias-" + index,
                            attrs: {
                              label: index == 0 ? "药品名称:" : "",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入药品名称",
                                  trigger: "blur",
                                },
                              ],
                              prop: "dkbDpAliasList." + index + ".alias",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px", float: "left" },
                              attrs: {
                                placeholder: "请输入药品名称",
                                maxlength: "300",
                                clearable: "",
                                "show-word-limit": "",
                              },
                              model: {
                                value: item.alias,
                                callback: function ($$v) {
                                  _vm.$set(item, "alias", $$v)
                                },
                                expression: "item.alias",
                              },
                            }),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "80px", float: "left" },
                                attrs: { placeholder: "请选择语言" },
                                model: {
                                  value: item.aliasLangue,
                                  callback: function ($$v) {
                                    _vm.$set(item, "aliasLangue", $$v)
                                  },
                                  expression: "item.aliasLangue",
                                },
                              },
                              _vm._l(_vm.languageConcep, function (item) {
                                return _c("el-option", {
                                  key: item.conceptId,
                                  attrs: {
                                    label: item.conceptVal,
                                    value: item.conceptId,
                                  },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  float: "left",
                                  "margin-bottom": "0px",
                                },
                                attrs: {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择名称类型",
                                      trigger: "blur",
                                    },
                                    {
                                      validator: _vm.validateAliasType,
                                      trigger: "blur",
                                    },
                                  ],
                                  prop:
                                    "dkbDpAliasList." + index + ".aliasType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "120px",
                                      float: "left",
                                    },
                                    attrs: { placeholder: "请选择类型" },
                                    model: {
                                      value: item.aliasType,
                                      callback: function ($$v) {
                                        _vm.$set(item, "aliasType", $$v)
                                      },
                                      expression: "item.aliasType",
                                    },
                                  },
                                  _vm._l(_vm.aliasTypeConcept, function (item) {
                                    return _c("el-option", {
                                      key: item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass: "el-icon-remove",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.delAlias(item)
                                },
                              },
                            }),
                            _c(
                              "el-radio",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  padding: "8px 8px 0 5px",
                                },
                                attrs: {
                                  label: index,
                                  border: "",
                                  size: "small",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.primaryChange(
                                      $event,
                                      "aliasPrimary",
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.dkbDp.aliasPrimary,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dkbDp, "aliasPrimary", $$v)
                                  },
                                  expression: "dkbDp.aliasPrimary",
                                },
                              },
                              [_vm._v(" 首选项 ")]
                            ),
                            index == _vm.dkbDp.dkbDpAliasList.length - 1
                              ? _c("el-button", {
                                  staticStyle: {
                                    "margin-left": "4px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    icon: "el-icon-document-copy",
                                    type: "primary",
                                    title: "复制制剂名称",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyName(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            index == _vm.dkbDp.dkbDpAliasList.length - 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "addData",
                                    staticStyle: {
                                      width: "80px",
                                      padding: "0px 0px 0px 16px",
                                    },
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addAlias("中文")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-circle-plus",
                                      staticStyle: {
                                        color: "#7C9DF7",
                                        width: "16px",
                                        height: "16px",
                                        display: "inline-block",
                                        float: "left",
                                      },
                                    }),
                                    _c("svg-icon", {
                                      staticStyle: {
                                        width: "24px",
                                        height: "16px",
                                      },
                                      attrs: { "icon-class": "china" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            index == _vm.dkbDp.dkbDpAliasList.length - 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "addData",
                                    staticStyle: {
                                      width: "80px",
                                      padding: "0px 0px 0px 16px",
                                    },
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addAlias("英语")
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-circle-plus",
                                      staticStyle: {
                                        color: "#7C9DF7",
                                        width: "16px",
                                        height: "16px",
                                        display: "inline-block",
                                        float: "left",
                                      },
                                    }),
                                    _c("svg-icon", {
                                      staticStyle: {
                                        width: "24px",
                                        height: "16px",
                                      },
                                      attrs: { "icon-class": "yingguoguoqi" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm.formDisplay.alias && !_vm.formDisplay.base
                        ? _c(
                            "el-col",
                            {
                              staticStyle: {
                                height: "80px",
                                "padding-right": "100px",
                              },
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { float: "right" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.submitAliasForm },
                                },
                                [_vm._v("保存别名")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.formDisplay.base
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm._l(
                            _vm.dkbDp.dkbDpManufacturerList,
                            function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: "dkbDp-manufacturer-" + index,
                                  attrs: {
                                    label: index == 0 ? "企业:" : "",
                                    rules: [
                                      {
                                        required: true,
                                        message: "企业不能为空",
                                        trigger: "blur",
                                      },
                                      {
                                        validator: function (
                                          rule,
                                          value,
                                          callback
                                        ) {
                                          _vm.validateManufacturer(
                                            rule,
                                            value,
                                            callback,
                                            index,
                                            item
                                          )
                                        },
                                        trigger: "blur",
                                      },
                                    ],
                                    prop:
                                      "dkbDpManufacturerList." +
                                      index +
                                      ".manufacturerId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "500px" },
                                      attrs: {
                                        filterable: "",
                                        remote: "",
                                        "reserve-keyword": "",
                                        placeholder: "请输入关键字搜索",
                                        "remote-method": _vm.queryManufacturer,
                                        loading: _vm.loading,
                                      },
                                      model: {
                                        value: item.manufacturerId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "manufacturerId", $$v)
                                        },
                                        expression: "item.manufacturerId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.manufacturerList.length > 0
                                        ? _vm.manufacturerList
                                        : [
                                            {
                                              id: item.manufacturerId,
                                              name: item.manufacturerName,
                                            },
                                          ],
                                      function (i) {
                                        return _c("el-option", {
                                          key:
                                            index + "-manufacturerId-" + i.id,
                                          attrs: { label: i.name, value: i.id },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "200px",
                                        float: "left",
                                      },
                                      attrs: { placeholder: "请选择企业类型" },
                                      model: {
                                        value: item.manufacturerType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "manufacturerType",
                                            $$v
                                          )
                                        },
                                        expression: "item.manufacturerType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.manufacturerTypeDict,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.conceptId,
                                          attrs: {
                                            label: item.conceptVal,
                                            value: item.conceptId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        title: "查询结果",
                                        width: "500",
                                        trigger: "click",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-table",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: _vm.loading,
                                                  expression: "loading",
                                                },
                                              ],
                                              attrs: {
                                                data: _vm.relationManufacturerList,
                                                height: 200,
                                                border: "",
                                                "header-cell-style": {
                                                  "text-align": "center",
                                                },
                                                "cell-style": {
                                                  "text-align": "center",
                                                },
                                                stripe: "",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  "min-width": "200",
                                                  property: "name",
                                                  label: "厂家名称",
                                                  sortable: "",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  "min-width": "120",
                                                  property: "manufacturerCode",
                                                  label: "厂家代码",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "操作",
                                                  width: "80",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                size: "mini",
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectRelationManufacturer(
                                                                      scope.row,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("选择")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-button", {
                                        staticStyle: {
                                          "margin-left": "4px",
                                          display: "inline-block",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          icon: "el-icon-search",
                                          type: "primary",
                                          id:
                                            "queryRelationManufacturerBut" +
                                            index,
                                          title:
                                            "根据本位码和批准文号匹配生产企业",
                                        },
                                        on: {
                                          click: _vm.queryRelationManufacturer,
                                        },
                                        slot: "reference",
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-remove",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.delManufacturer(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "addData",
                                  staticStyle: { width: "500px" },
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addManufacturer("")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus el-icon--left",
                                    staticStyle: {
                                      color: "#7C9DF7",
                                      width: "16px",
                                      height: "16px",
                                      display: "inline-block",
                                    },
                                  }),
                                  _vm._v(" 添加生产企业 "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                [
                  _vm.formDisplay.approvalNum
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm._l(
                            _vm.dkbDp.dkbDpApprovalNumList,
                            function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: "dkbDp-ApprovalNum-" + index,
                                  attrs: {
                                    label: index == 0 ? "批准文号:" : "",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入批准文号",
                                        trigger: "blur",
                                      },
                                      {
                                        validator: _vm.validateApprovalNum,
                                        trigger: "blur",
                                      },
                                    ],
                                    prop:
                                      "dkbDpApprovalNumList." +
                                      index +
                                      ".approvalNum",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: {
                                        width: "300px",
                                        float: "left",
                                      },
                                      attrs: {
                                        placeholder: "请输入批准文号",
                                        maxlength: "50",
                                        clearable: "",
                                        "show-word-limit": "",
                                      },
                                      nativeOn: {
                                        change: function ($event) {
                                          return _vm.approvalNumInputChange(
                                            item.approvalNum,
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.approvalNum,
                                        callback: function ($$v) {
                                          _vm.$set(item, "approvalNum", $$v)
                                        },
                                        expression: "item.approvalNum",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showApprovalNumDialog(
                                              index
                                            )
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: {
                                      width: "150px",
                                      float: "left",
                                    },
                                    attrs: {
                                      type: "date",
                                      clearable: false,
                                      placeholder: "批准日期",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.approveDate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "approveDate", $$v)
                                      },
                                      expression: "item.approveDate",
                                    },
                                  }),
                                  _c("el-date-picker", {
                                    staticStyle: {
                                      width: "150px",
                                      float: "left",
                                    },
                                    attrs: {
                                      type: "date",
                                      clearable: false,
                                      placeholder: "发证日期",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.certificateDate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "certificateDate", $$v)
                                      },
                                      expression: "item.certificateDate",
                                    },
                                  }),
                                  _c("el-date-picker", {
                                    staticStyle: {
                                      width: "150px",
                                      float: "left",
                                    },
                                    attrs: {
                                      type: "date",
                                      clearable: false,
                                      placeholder: "有效期截止日",
                                      "value-format": "yyyy-MM-dd",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.validityDate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "validityDate", $$v)
                                      },
                                      expression: "item.validityDate",
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-remove",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.delApprovalNum(item)
                                      },
                                    },
                                  }),
                                  item.isPzwh === true
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#ffba00" } },
                                        [_vm._v("批准文号有误，请校验！")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "addData",
                                  staticStyle: { width: "500px" },
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addApprovalNum("")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus el-icon--left",
                                    staticStyle: {
                                      color: "#7C9DF7",
                                      width: "16px",
                                      height: "16px",
                                      display: "inline-block",
                                    },
                                  }),
                                  _vm._v(" 添加批准文号 "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.formDisplay.approvalNum && !_vm.formDisplay.base
                    ? _c(
                        "el-col",
                        {
                          staticStyle: {
                            height: "80px",
                            "padding-right": "100px",
                          },
                          attrs: { span: 20 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { type: "primary" },
                              on: { click: _vm.submitApprovalNumForm },
                            },
                            [_vm._v("保存批准文号")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formDisplay.base
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm._l(
                            _vm.dkbDp.dkbDpStandardCodeList,
                            function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: "dkbDp-standardCode-" + index,
                                  attrs: {
                                    label: index == 0 ? "本位码:" : "",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入本位码",
                                        trigger: "blur",
                                      },
                                      {
                                        validator: function (
                                          rule,
                                          value,
                                          callback
                                        ) {
                                          _vm.validateStandardCode(
                                            rule,
                                            value,
                                            callback,
                                            index
                                          )
                                        },
                                        trigger: "blur",
                                      },
                                    ],
                                    prop:
                                      "dkbDpStandardCodeList." +
                                      index +
                                      ".standardCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "400px",
                                      float: "left",
                                    },
                                    attrs: {
                                      placeholder: "请输入本位码",
                                      maxlength: "50",
                                      clearable: "",
                                      "show-word-limit": "",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.standardCodeInputBlur(index)
                                      },
                                    },
                                    model: {
                                      value: item.standardCode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "standardCode", $$v)
                                      },
                                      expression: "item.standardCode",
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-remove",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.delStandardCode(item)
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.flag1 && !item.flag2,
                                          expression:
                                            "item.flag1 && !item.flag2",
                                        },
                                      ],
                                      staticClass: "el-form-item__error",
                                      staticStyle: {
                                        "margin-left": "100px",
                                        color: "#E6A23C",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("本位码已存在,药品id为:"),
                                      ]),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: {
                                            type: "warning",
                                            title: "点击查看药品详情",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editDrugProd(
                                                item.flag1DrugProdId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.flag1DrugProdId))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.flag2,
                                          expression: "item.flag2",
                                        },
                                      ],
                                      staticClass: "el-form-item__error",
                                      staticStyle: { "margin-left": "100px" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "制剂规格和本位码已存在,药品id为:"
                                        ),
                                      ]),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: {
                                            type: "danger",
                                            title: "点击查看药品详情",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editDrugProd(
                                                item.flag2DrugProdId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.flag2DrugProdId))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "addData",
                                  staticStyle: { width: "500px" },
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addStandardCode("")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus el-icon--left",
                                    staticStyle: {
                                      color: "#7C9DF7",
                                      width: "16px",
                                      height: "16px",
                                      display: "inline-block",
                                    },
                                  }),
                                  _vm._v(" 添加本位码 "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.formDisplay.specDes
                    ? _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _vm._l(
                            _vm.dkbDp.dkbDpSpecDescList,
                            function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: "dkbDp-ApprovalNum-" + index,
                                  attrs: {
                                    label: index == 0 ? "规格描述:" : "",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入规格描述",
                                        trigger: "blur",
                                      },
                                    ],
                                    prop:
                                      "dkbDpSpecDescList." +
                                      index +
                                      ".specDesc",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "500px",
                                      float: "left",
                                    },
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入规格描述",
                                      maxlength: "500",
                                      clearable: "",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: item.specDesc,
                                      callback: function ($$v) {
                                        _vm.$set(item, "specDesc", $$v)
                                      },
                                      expression: "item.specDesc",
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-remove",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.delSpecDesc(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "addData",
                                  staticStyle: { width: "500px" },
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addSpecDesc },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus el-icon--left",
                                    staticStyle: {
                                      color: "#7C9DF7",
                                      width: "16px",
                                      height: "16px",
                                      display: "inline-block",
                                    },
                                  }),
                                  _vm._v(" 添加规格描述 "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.formDisplay.base
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "国产进口标识:",
                                prop: "domesticOrImport",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.dkbDp.domesticOrImport,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dkbDp,
                                        "domesticOrImport",
                                        $$v
                                      )
                                    },
                                    expression: "dkbDp.domesticOrImport",
                                  },
                                },
                                _vm._l(
                                  _vm.domesticOrImportDict,
                                  function (item) {
                                    return _c(
                                      "el-radio-button",
                                      {
                                        key: item.conceptId,
                                        attrs: { label: item.conceptId },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.conceptVal) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "原研药标记:",
                                prop: "originalDrug",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.dkbDp.originalDrug,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dkbDp, "originalDrug", $$v)
                                    },
                                    expression: "dkbDp.originalDrug",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_vm._v("未知")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("否")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "一致性评价标记:",
                                prop: "consistencyEvaluation",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.dkbDp.consistencyEvaluation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dkbDp,
                                        "consistencyEvaluation",
                                        $$v
                                      )
                                    },
                                    expression: "dkbDp.consistencyEvaluation",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 2 } },
                                    [_vm._v("未知")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 1 } },
                                    [_vm._v("是")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: 0 } },
                                    [_vm._v("否")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.pdProd
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "title-bar",
                          attrs: { span: 24, id: "a03" },
                        },
                        [_vm._v("包装药品")]
                      ),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", { staticStyle: { "font-size": "16px" } }, [
                          _vm._v("相关包装列表"),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#2949A5",
                            },
                          },
                          [
                            _vm._v(
                              "（注：由于组合包装涉及多个表，此处只做信息查看，不做数据维护。需到“组合包装管理”功能界面进行维护）"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-bottom": "10px" },
                          attrs: { span: 4 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.editPdProd()
                                },
                              },
                            },
                            [_vm._v("添加包装药品 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.pdProd
                ? _c(
                    "el-row",
                    { staticStyle: { padding: "0px 0px 15px 15px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "rno",
                              attrs: {
                                border: "",
                                stripe: "",
                                "header-cell-style": { "text-align": "center" },
                                "cell-style": { "text-align": "center" },
                                data: _vm.dkbDp.dkbPdProdList,
                                height: _vm.pdProdTableHeight,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  property: "id",
                                  label: "包装药品ID",
                                  width: "80",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "包装药品名称", width: "120" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                            },
                                            [_vm._v(_vm._s(scope.row.drugName))]
                                          ),
                                          _vm._l(
                                            scope.row.drugCategoryList,
                                            function (item) {
                                              return _c(
                                                "span",
                                                { key: item.id },
                                                [
                                                  item.icon
                                                    ? _c("svg-icon", {
                                                        attrs: {
                                                          "icon-class":
                                                            item.icon,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  703780962
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "packSpecDesc",
                                  label: "规格描述",
                                  width: "80",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "packTypeDesc",
                                  label: "包装类型",
                                  width: "80",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "packQty",
                                  label: "数量",
                                  width: "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.packQty) +
                                              " " +
                                              _vm._s(scope.row.specUnitDesc) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "18px",
                                                "font-weight": "bold",
                                              },
                                            },
                                            [_vm._v("/")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.packQtyUnitDesc
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3214249912
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "包装材料",
                                  "min-width": "200",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return _vm._l(
                                          scope.row.pdProdMatList,
                                          function (item, index) {
                                            return _c(
                                              "P",
                                              {
                                                key:
                                                  item.id +
                                                  index +
                                                  item.wrapperDesc +
                                                  "pdProdMatList",
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "[" + (index + 1) + "]"
                                                    ) +
                                                    _vm._s(item.wrapperDesc) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          index <
                                                          scope.row
                                                            .pdProdMatList
                                                            .length -
                                                            1,
                                                        expression:
                                                          "index<scope.row.pdProdMatList.length-1",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v(";")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.isEmpty(
                                                          item.stateMedicareNum
                                                        ),
                                                        expression:
                                                          "!isEmpty(item.stateMedicareNum)",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "国家医保代码:" +
                                                        _vm._s(
                                                          item.stateMedicareNum
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.isEmpty(
                                                          item.shMedicareNum
                                                        ),
                                                        expression:
                                                          "!isEmpty(item.shMedicareNum)",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "上海医保代码:" +
                                                        _vm._s(
                                                          item.shMedicareNum
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1756497416
                                ),
                              }),
                              _c("el-table-column", {
                                key: "status",
                                attrs: {
                                  label: "状态",
                                  align: "center",
                                  width: "74",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-switch", {
                                            staticClass: "switch",
                                            attrs: {
                                              "active-color": "#5DB730",
                                              "active-text": "启用",
                                              "inactive-text": "停用",
                                              "inactive-color": "#B5CCF5",
                                              disabled: scope.row.packType == 2,
                                              "active-value": "0",
                                              "inactive-value": "1",
                                            },
                                            model: {
                                              value: scope.row.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.status",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1553998548
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "120",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  scope.row.packType == 2,
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editPdProd(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  scope.row.packType == 2,
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyPdProd(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("复制 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  scope.row.packType == 2,
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editPdSpec(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("拆分 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  scope.row.packType == 2,
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewPdProdMatList(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("包装材料维护 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { color: "#FF3333" },
                                              attrs: {
                                                disabled:
                                                  scope.row.packType == 2,
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delPdProd(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  scope.row.packType == 2,
                                                size: "small",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleResource(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("来源 ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2984953877
                                ),
                              }),
                              _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-booking.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v("暂无数据")]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.formDisplay.pdProd && !_vm.formDisplay.base
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { height: "60px", padding: "10px" },
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { float: "right" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.submitPdProdForm },
                                },
                                [_vm._v("保存包装药品 ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-col", {
                        staticStyle: { height: "50px", padding: "10px" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.specIngredient
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "title-bar",
                          attrs: { span: 24, id: "a02" },
                        },
                        [_vm._v("药品成分(包含成分)")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-bottom": "10px" },
                          attrs: { span: 24 },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right" },
                              attrs: { type: "primary" },
                              on: { click: _vm.editSpecIngredient },
                            },
                            [_vm._v("添加成分 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.specIngredient
                ? _c(
                    "el-row",
                    { staticStyle: { padding: "0px 0px 15px 15px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "rno",
                              attrs: {
                                border: "",
                                stripe: "",
                                "header-cell-style": { "text-align": "center" },
                                "cell-style": { "text-align": "center" },
                                data: _vm.dkbDp.dkbDpSpecIngredientList,
                                height: _vm.specIngredientTableHeight,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  property: "id",
                                  label: "成分ID",
                                  width: "100",
                                  sortable: "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "substanceName",
                                  label: "物质",
                                  "min-width": "100",
                                  sortable: "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "substanceQtyMax",
                                  label: "物质数量",
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.substanceQtyMax
                                              ) +
                                              " " +
                                              _vm._s(
                                                scope.row.substanceQtyUnitDesc
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4075436723
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "ingredientTypeDesc",
                                  label: "成分类型",
                                  width: "100",
                                  sortable: "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  property: "substanceReferenceDesc",
                                  label: "物质参考说明",
                                  "min-width": "100",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "150",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editSpecIngredient(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { color: "#FF3333" },
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delSpecIngredient(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除 ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2234734813
                                ),
                              }),
                              _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-booking.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v("暂无数据")]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.specIngredient && !_vm.formDisplay.base
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "primary" },
                          on: { click: _vm.submitSpecIngredientForm },
                        },
                        [_vm._v(" 保存成分 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formDisplay.base
                ? _c("el-col", {
                    staticClass: "bottom-bar",
                    attrs: { span: 20 },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _vm.formDisplay.base
            ? _c(
                "el-col",
                {
                  staticClass: "bottom-bar",
                  staticStyle: {
                    height: "80px",
                    "padding-right": "0px",
                    position: "fixed",
                    right: "30px",
                    bottom: "10px",
                  },
                  attrs: { span: 20 },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dkbDp.id,
                          expression: "dkbDp.id",
                        },
                      ],
                      staticStyle: { float: "right", "margin-left": "10px" },
                      on: { click: _vm.editNext },
                    },
                    [_vm._v("下一个")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dkbDp.id,
                          expression: "dkbDp.id",
                        },
                      ],
                      staticStyle: { float: "right", "margin-left": "10px" },
                      on: { click: _vm.editPrev },
                    },
                    [_vm._v("上一个")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-left": "10px" },
                      on: { click: _vm.handleReturn },
                    },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(1)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(2)
                        },
                      },
                    },
                    [_vm._v("保存并关闭")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "制剂检索",
            "append-to-body": "",
            width: "60%",
            visible: _vm.preparation.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.preparation, "dialogVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "preparationQueryForm",
              attrs: { "status-icon": "", model: _vm.preparation },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "400px", float: "left" },
                    attrs: {
                      placeholder: "请输入关键字",
                      maxlength: "20",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryPreparation($event)
                      },
                    },
                    model: {
                      value: _vm.preparation.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.preparation, "searchValue", $$v)
                      },
                      expression: "preparation.searchValue",
                    },
                  }),
                  _c("el-input", { staticStyle: { display: "none" } }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                      },
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.queryPreparation },
                    },
                    [_vm._v("检索 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                        float: "right",
                      },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.addPreparation },
                    },
                    [_vm._v("新增制剂 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.preparation.tableData,
                height: 300,
                "default-sort": {
                  prop: "preparationName",
                  order: "descending",
                },
              },
              on: { "sort-change": _vm.ppSpecTableSortChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "preparationSpecId",
                  label: "规格id",
                  width: "100",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "preparationName",
                  label: "制剂名称",
                  "min-width": "150",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "preparationForm",
                  label: "制剂剂型",
                  width: "100",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "preparationSpecDesc",
                  label: "规格描述",
                  "min-width": "150",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.choosePreparation(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isDrugProd === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.preparation.total > 0,
                expression: "preparation.total>0",
              },
            ],
            attrs: {
              total: _vm.preparation.total,
              page: _vm.preparation.pageNum,
              limit: _vm.preparation.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.preparation, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.preparation, "pageSize", $event)
              },
              pagination: _vm.queryPreparation,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "成分编辑",
            "append-to-body": "",
            width: "60%",
            visible: _vm.addSpecIngredientDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSpecIngredientDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "addSpecIngredientForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.specIngredientForm,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "成分物质:",
                                prop: "substanceId",
                                rules: _vm.specIngredientFormRule.substanceId,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "280px",
                                    float: "left",
                                  },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                    placeholder: "请输入关键词搜索",
                                    "remote-method": _vm.querySubstanceName,
                                    loading: _vm.loading,
                                  },
                                  model: {
                                    value: _vm.specIngredientForm.substanceId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "substanceId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.substanceId",
                                  },
                                },
                                _vm._l(_vm.substanceList, function (item) {
                                  return _c("el-option", {
                                    key: "substanceList" + item.substanceId,
                                    attrs: {
                                      label: item.name,
                                      value: item.substanceId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "成分类型:",
                                prop: "ingredientType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    float: "left",
                                  },
                                  model: {
                                    value:
                                      _vm.specIngredientForm.ingredientType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "ingredientType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.ingredientType",
                                  },
                                },
                                _vm._l(
                                  _vm.drugIngredientTypeDict,
                                  function (item) {
                                    return _c("el-option", {
                                      key:
                                        "drugIngredientTypeDict" +
                                        item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质数量:",
                                prop: "substanceQtyMax",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "150px" },
                                attrs: {
                                  min: 0,
                                  controls: "",
                                  "controls-position": "right",
                                  precision: 4,
                                },
                                model: {
                                  value: _vm.specIngredientForm.substanceQtyMax,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specIngredientForm,
                                      "substanceQtyMax",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "specIngredientForm.substanceQtyMax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质数量单位:",
                                prop: "substanceQtyUnitId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "150px",
                                    float: "left",
                                  },
                                  attrs: {
                                    filterable: "",
                                    "filter-method": _vm.substanceQtyUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.quantityUnitDictOptions =
                                        _vm.quantityUnitDict
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.specIngredientForm.substanceQtyUnitId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.specIngredientForm,
                                        "substanceQtyUnitId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "specIngredientForm.substanceQtyUnitId",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "", value: "" },
                                  }),
                                  _vm._l(
                                    _vm.quantityUnitDictOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key:
                                          "quantityUnitDict" + item.conceptId,
                                        attrs: {
                                          label: item.conceptVal,
                                          value: item.conceptId,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物质参考说明:",
                                prop: "substanceReferenceDesc",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px", float: "left" },
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入物质参考说明",
                                  maxlength: "200",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value:
                                    _vm.specIngredientForm
                                      .substanceReferenceDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specIngredientForm,
                                      "substanceReferenceDesc",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "specIngredientForm.substanceReferenceDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmSpecIngredient },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: { click: _vm.cancelSpecIngredient },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "编辑包装药品",
            width: "60%",
            "append-to-body": "",
            visible: _vm.editPdProdDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPdProdDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "editPdProdForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.pdProdForm,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "包装药品名称:",
                                prop: "drugName",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入包装药品名称",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  maxlength: "300",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.pdProdForm.drugName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pdProdForm, "drugName", $$v)
                                  },
                                  expression: "pdProdForm.drugName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "包装规格描述:",
                                prop: "packSpecDesc",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入包装规格描述",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  maxlength: "200",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.pdProdForm.packSpecDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.pdProdForm,
                                      "packSpecDesc",
                                      $$v
                                    )
                                  },
                                  expression: "pdProdForm.packSpecDesc",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "light",
                                    content: "根据已填写的数量填充包装规格描述",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: { click: _vm.fillPackSpecDesc },
                                    },
                                    [_vm._v("填写包装规格描述")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数量:",
                                prop: "packQty",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入数量",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "220px" },
                                attrs: {
                                  min: 0,
                                  max: 100000000,
                                  controls: "",
                                  "controls-position": "right",
                                  precision: 2,
                                },
                                model: {
                                  value: _vm.pdProdForm.packQty,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.pdProdForm, "packQty", $$v)
                                  },
                                  expression: "pdProdForm.packQty",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    filterable: "",
                                    "filter-method": _vm.packQtyUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.packQtyUnitDictOptions =
                                        _vm.packQtyUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.pdProdForm.specUnit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.pdProdForm, "specUnit", $$v)
                                    },
                                    expression: "pdProdForm.specUnit",
                                  },
                                },
                                _vm._l(
                                  _vm.packQtyUnitDictOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key:
                                        "pdProdForm.specUnit" + item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    margin: "5px",
                                    "font-size": "20px",
                                    "font-weight": "bold",
                                  },
                                },
                                [_vm._v("/")]
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    filterable: "",
                                    "filter-method": _vm.packQtyUnitFilter,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      _vm.packQtyUnitDictOptions =
                                        _vm.packQtyUnitDict
                                    },
                                  },
                                  model: {
                                    value: _vm.pdProdForm.packQtyUnitId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.pdProdForm,
                                        "packQtyUnitId",
                                        $$v
                                      )
                                    },
                                    expression: "pdProdForm.packQtyUnitId",
                                  },
                                },
                                _vm._l(
                                  _vm.packQtyUnitDictOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key:
                                        "pdProdForm.packQtyUnitId" +
                                        item.conceptId,
                                      attrs: {
                                        label: item.conceptVal,
                                        value: item.conceptId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmPdProd },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: { click: _vm.cancelPdProd },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "包装药品明细拆分",
            width: "60%",
            "append-to-body": "",
            visible: _vm.editPdSpecDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPdSpecDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPdSpecForm",
              attrs: {
                "status-icon": "",
                model: _vm.pdSpecForm,
                "label-width": "10px",
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    prop: "level1PackQty",
                    rules: [
                      {
                        required: true,
                        message: "请输入数量",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      min: 0,
                      max: 10000,
                      controls: "",
                      "controls-position": "right",
                      precision: 2,
                    },
                    model: {
                      value: _vm.pdSpecForm.level1PackQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.pdSpecForm, "level1PackQty", $$v)
                      },
                      expression: "pdSpecForm.level1PackQty",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.pdSpecForm.specUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.pdSpecForm, "specUnit", $$v)
                        },
                        expression: "pdSpecForm.specUnit",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "specUnit" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "5px",
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("/")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: {
                        filterable: "",
                        "filter-method": _vm.packQtyUnitFilter,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$forceUpdate()
                        },
                        focus: function ($event) {
                          _vm.packQtyUnitDictOptions = _vm.packQtyUnitDict
                        },
                      },
                      model: {
                        value: _vm.pdSpecForm.level1PackQtyUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pdSpecForm, "level1PackQtyUnitId", $$v)
                        },
                        expression: "pdSpecForm.level1PackQtyUnitId",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "specUnit" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "10px",
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("*")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    prop: "level2PackQty",
                    rules: [
                      { required: true, message: "请输入", trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      min: 0,
                      max: 10000,
                      controls: "",
                      "controls-position": "right",
                      precision: 2,
                    },
                    model: {
                      value: _vm.pdSpecForm.level2PackQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.pdSpecForm, "level2PackQty", $$v)
                      },
                      expression: "pdSpecForm.level2PackQty",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.pdSpecForm.level1PackQtyUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pdSpecForm, "level1PackQtyUnitId", $$v)
                        },
                        expression: "pdSpecForm.level1PackQtyUnitId",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "specUnit" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "5px",
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("/")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.pdSpecForm.packQtyUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pdSpecForm, "packQtyUnitId", $$v)
                        },
                        expression: "pdSpecForm.packQtyUnitId",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "packQtyUnitId" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { margin: "10px 0px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-circle-check",
                            size: "small",
                          },
                          on: { click: _vm.confirmEditPdSpec },
                        },
                        [_vm._v("确定 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small",
                          },
                          on: { click: _vm.cancelEditPdSpec },
                        },
                        [_vm._v("取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: { title: "引用来源管理", visible: _vm.resourceTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableForm },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableForm.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      property: "date",
                      label: "引用资源类型",
                      align: "center",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择物质名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resourceTypeChange(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "name",
                      align: "center",
                      label: "引用资源名称",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: "resourceDataOption" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  istyle: "color: #FF3333;",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-check",
                        size: "small",
                      },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("确定 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { icon: "el-icon-circle-close", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "选择本位码",
            width: "60%",
            "append-to-body": "",
            visible: _vm.standardCodeSelectDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.standardCodeSelectDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { height: "400px", overflow: "auto" } },
            [
              _c(
                "p",
                { staticStyle: { "font-size": "15px", "font-weight": "bold" } },
                [
                  _vm._v(
                    "根据当前已输入的批准文号，匹配到多个本位码，请选择一个本位码"
                  ),
                ]
              ),
              _c("el-divider"),
              _c(
                "ul",
                { staticStyle: { "font-size": "15px" } },
                _vm._l(_vm.cfdaDrugList, function (item, index) {
                  return _c(
                    "li",
                    [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(_vm._s(item.approvalNum) + ":"),
                      ]),
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(_vm._s(item.standardCode)),
                      ]),
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(_vm._s(item.drugName)),
                      ]),
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(_vm._s(item.standardCodeRemark)),
                      ]),
                      item.existFlag
                        ? _c(
                            "el-tag",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { size: "small" },
                            },
                            [_vm._v("已添加")]
                          )
                        : _vm._e(),
                      !item.existFlag
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectStandardCode(index)
                                },
                              },
                            },
                            [_vm._v("选择")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "包装材料明细",
            width: "60%",
            "append-to-body": "",
            visible: _vm.pdProdMatListDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.pdProdMatListDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.editPdProdMat()
                        },
                      },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.pdProdMatList,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "id", label: "包装材料ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "wrapperDesc",
                  label: "包装材料描述",
                  "min-width": "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "storage",
                  label: "储藏条件",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "有效期", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.indate) +
                            " " +
                            _vm._s(scope.row.indateUnitDesc) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  property: "barcode69",
                  label: "编码",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.barcode69,
                                expression: "scope.row.barcode69",
                              },
                            ],
                          },
                          [_vm._v("69条形码: " + _vm._s(scope.row.barcode69))]
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.stateMedicareNumList.toString() !=
                                  "",
                                expression:
                                  "scope.row.stateMedicareNumList.toString()!=''",
                              },
                            ],
                          },
                          [
                            _vm._v("国家医保统编码: "),
                            _vm._l(
                              scope.row.stateMedicareNumList,
                              function (item, index) {
                                return _c(
                                  "span",
                                  { key: item + "stateMedicareNum" },
                                  [
                                    _vm._v(" " + _vm._s(item)),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              index <
                                              scope.row.stateMedicareNumList
                                                .length -
                                                1,
                                            expression:
                                              "index<scope.row.stateMedicareNumList.length-1",
                                          },
                                        ],
                                      },
                                      [_vm._v(";")]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  scope.row.shMedicareNumList.toString() != "",
                                expression:
                                  "scope.row.shMedicareNumList.toString()!=''",
                              },
                            ],
                          },
                          [
                            _vm._v("上海医保统编码: "),
                            _vm._l(
                              scope.row.shMedicareNumList,
                              function (item, index) {
                                return _c(
                                  "span",
                                  { key: item + "shMedicareNum" },
                                  [
                                    _vm._v(" " + _vm._s(item)),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              index <
                                              scope.row.shMedicareNumList
                                                .length -
                                                1,
                                            expression:
                                              "index<scope.row.shMedicareNumList.length-1",
                                          },
                                        ],
                                      },
                                      [_vm._v(";")]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editPdProdMat(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#FF3333" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delPdProdMat(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { margin: "10px 0px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        icon: "el-icon-circle-check",
                        size: "small",
                      },
                      on: { click: _vm.confirmEditPdProdMatList },
                    },
                    [_vm._v("确定 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { icon: "el-icon-circle-close", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.pdProdMatListDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "编辑包装材料",
            width: "600px",
            "append-to-body": "",
            visible: _vm.editPdProdMatDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPdProdMatDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "editPdProdMatForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.editPdProdMatForm,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "包装材料描述:",
                                prop: "wrapperDesc",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入包装材料描述",
                                    trigger: "blur",
                                  },
                                  {
                                    validator: _vm.validateWrapperDesc,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px", float: "left" },
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入包装材料描述",
                                  maxlength: "1000",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.wrapperDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "wrapperDesc",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.wrapperDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "储藏条件:", prop: "storage" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  maxlength: "200",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.storage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "storage",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.storage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "有效期:",
                                prop: "indate",
                                rules: [],
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "120px", float: "left" },
                                attrs: {
                                  min: 0,
                                  max: 10000,
                                  controls: "",
                                  "controls-position": "right",
                                  precision: 0,
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.indate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "indate",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.indate",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    float: "left",
                                  },
                                  model: {
                                    value: _vm.editPdProdMatForm.indateUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editPdProdMatForm,
                                        "indateUnit",
                                        $$v
                                      )
                                    },
                                    expression: "editPdProdMatForm.indateUnit",
                                  },
                                },
                                _vm._l(_vm.timeUnitDict, function (item) {
                                  return _c("el-option", {
                                    key: "indateUnit" + item.conceptId,
                                    attrs: {
                                      label: item.conceptVal,
                                      value: item.conceptId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "69条形码:", prop: "barcode69" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  maxlength: "30",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.barcode69,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "barcode69",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.barcode69",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        _vm._l(
                          _vm.editPdProdMatForm.stateMedicareNumList,
                          function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: "stateMedicareNum-" + index,
                                attrs: {
                                  label: index == 0 ? "国家医保统编码:" : "",
                                  rules: [
                                    {
                                      validator: _vm.validateMedicareNum,
                                      trigger: "change",
                                    },
                                  ],
                                  prop: "stateMedicareNumList." + index,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "320px",
                                    float: "left",
                                  },
                                  attrs: {
                                    placeholder: "请输入国家医保统编码",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.medicareNumInputFocus(
                                        "国家",
                                        index
                                      )
                                    },
                                  },
                                  nativeOn: {
                                    "!paste": function ($event) {
                                      $event.preventDefault()
                                      item == ""
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.editPdProdMatForm
                                        .stateMedicareNumList[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editPdProdMatForm
                                          .stateMedicareNumList,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editPdProdMatForm.stateMedicareNumList[index]",
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.editPdProdMatForm
                                          .stateMedicareNumList.length > 1,
                                      expression:
                                        "editPdProdMatForm.stateMedicareNumList.length>1",
                                    },
                                  ],
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.delMedicareNum(
                                        item,
                                        index,
                                        "国家"
                                      )
                                    },
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        index ==
                                          _vm.editPdProdMatForm
                                            .stateMedicareNumList.length -
                                            1 &&
                                        _vm.editPdProdMatForm
                                          .stateMedicareNumList.length < 5,
                                      expression:
                                        "index==editPdProdMatForm.stateMedicareNumList.length-1 && editPdProdMatForm.stateMedicareNumList.length<5",
                                    },
                                  ],
                                  staticClass: "el-icon-circle-plus",
                                  staticStyle: {
                                    "font-size": "20px",
                                    color: "#2B60F8",
                                    "margin-left": "4px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addMedicareNum("国家")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        _vm._l(
                          _vm.editPdProdMatForm.shMedicareNumList,
                          function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: "shMedicareNum-" + index,
                                attrs: {
                                  label: index == 0 ? "上海医保统编码:" : "",
                                  rules: [
                                    { validator: _vm.validateMedicareNum },
                                  ],
                                  prop: "shMedicareNumList." + index,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "320px",
                                    float: "left",
                                  },
                                  attrs: {
                                    placeholder: "请输入上海医保统编码",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.medicareNumInputFocus(
                                        "上海",
                                        index
                                      )
                                    },
                                  },
                                  nativeOn: {
                                    "!paste": function ($event) {
                                      $event.preventDefault()
                                      item == ""
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.editPdProdMatForm.shMedicareNumList[
                                        index
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editPdProdMatForm.shMedicareNumList,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editPdProdMatForm.shMedicareNumList[index]",
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.editPdProdMatForm.shMedicareNumList
                                          .length > 1,
                                      expression:
                                        "editPdProdMatForm.shMedicareNumList.length>1",
                                    },
                                  ],
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.delMedicareNum(
                                        item,
                                        index,
                                        "上海"
                                      )
                                    },
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        index ==
                                          _vm.editPdProdMatForm
                                            .shMedicareNumList.length -
                                            1 &&
                                        _vm.editPdProdMatForm.shMedicareNumList
                                          .length < 5,
                                      expression:
                                        "index==editPdProdMatForm.shMedicareNumList.length-1 && editPdProdMatForm.shMedicareNumList.length<5",
                                    },
                                  ],
                                  staticClass: "el-icon-circle-plus",
                                  staticStyle: {
                                    "font-size": "20px",
                                    color: "#2B60F8",
                                    "margin-left": "4px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addMedicareNum("上海")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmPdProdMat },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.editPdProdMatDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "批准文号检索",
            "append-to-body": "",
            width: "70%",
            visible: _vm.approvalNumDialog.visible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.approvalNumDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "approvalNumQueryForm",
              attrs: {
                "status-icon": "",
                model: _vm.approvalNumDialog.queryParams,
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", float: "left" },
                    attrs: {
                      placeholder: "请输入药品名称",
                      maxlength: "20",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryApprovalNum($event)
                      },
                    },
                    model: {
                      value: _vm.approvalNumDialog.queryParams.drugName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.approvalNumDialog.queryParams,
                          "drugName",
                          $$v
                        )
                      },
                      expression: "approvalNumDialog.queryParams.drugName",
                    },
                  }),
                  _c("el-input", {
                    staticStyle: {
                      width: "300px",
                      float: "left",
                      "margin-left": "10px",
                    },
                    attrs: {
                      placeholder: "请输入药品厂家",
                      maxlength: "20",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryApprovalNum($event)
                      },
                    },
                    model: {
                      value: _vm.approvalNumDialog.queryParams.manufacturer,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.approvalNumDialog.queryParams,
                          "manufacturer",
                          $$v
                        )
                      },
                      expression: "approvalNumDialog.queryParams.manufacturer",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                      },
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.queryApprovalNum },
                    },
                    [_vm._v("检索 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.approvalNumDialog.tableData,
                height: 300,
                "default-sort": {
                  prop: "preparationName",
                  order: "descending",
                },
              },
              on: { "sort-change": _vm.approvalNumTableSortChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "drugName",
                  label: "药品名称",
                  "min-width": "200",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "dosageForm",
                  label: "剂型",
                  width: "100",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "spec",
                  label: "规格",
                  "min-width": "150",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.standardCodeRemark
                                ? scope.row.standardCodeRemark
                                : scope.row.spec
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  property: "domesticOrImport",
                  label: "国产/进口",
                  "min-width": "90",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.domesticOrImport == 1 ? "国产" : "进口"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  property: "manufacturerEn",
                  label: "生产企业",
                  "min-width": "150",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.manufacturerZh) +
                            " " +
                            _vm._s(scope.row.manufacturerEn) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  property: "mahEn",
                  label: "上市许可持有人",
                  "min-width": "150",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.mahEn) +
                            " " +
                            _vm._s(scope.row.mahZh) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  property: "approvalNum",
                  label: "批准文号",
                  width: "150",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "standardCode",
                  label: "本位码",
                  width: "150",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "70", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseApprovalNum(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.approvalNumDialog.total > 0,
                expression: "approvalNumDialog.total>0",
              },
            ],
            attrs: {
              total: _vm.approvalNumDialog.total,
              page: _vm.approvalNumDialog.queryParams.pageNum,
              limit: _vm.approvalNumDialog.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(
                  _vm.approvalNumDialog.queryParams,
                  "pageNum",
                  $event
                )
              },
              "update:limit": function ($event) {
                return _vm.$set(
                  _vm.approvalNumDialog.queryParams,
                  "pageSize",
                  $event
                )
              },
              pagination: _vm.queryApprovalNum,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "国家医保药品目录",
            width: "80%",
            "append-to-body": "",
            visible: _vm.nhsaDrugDictDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.nhsaDrugDictDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "drugDictQueryForm",
                  attrs: { "status-icon": "", model: _vm.dictDrugForm },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px", float: "left" },
                        attrs: {
                          placeholder: "请输入药品名称/批准文号/本位码",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.selectDictDrug("国家")
                          },
                        },
                        model: {
                          value: _vm.dictDrugForm.drugName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dictDrugForm, "drugName", $$v)
                          },
                          expression: "dictDrugForm.drugName",
                        },
                      }),
                      _c("el-input", { staticStyle: { display: "none" } }),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                          },
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.selectDictDrug("国家")
                            },
                          },
                        },
                        [_vm._v("检索 ")]
                      ),
                      _c(
                        "span",
                        { staticStyle: { margin: "0px 10px 0px 30px" } },
                        [_vm._v("快捷搜索:")]
                      ),
                      _vm._l(_vm.dkbDp.dkbDpAliasList, function (item, index) {
                        return _c(
                          "el-link",
                          {
                            key: index + item.alias,
                            staticStyle: { "margin-right": "15px" },
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.quickSearchDictDrug($event, "国家")
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.alias))]
                        )
                      }),
                      _vm._l(
                        _vm.dkbDp.dkbDpApprovalNumList,
                        function (item, index) {
                          return _c(
                            "el-link",
                            {
                              key: index + item.approvalNum,
                              staticStyle: { "margin-right": "15px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.quickSearchDictDrug($event, "国家")
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.approvalNum))]
                          )
                        }
                      ),
                      _vm._l(
                        _vm.dkbDp.dkbDpStandardCodeList,
                        function (item, index) {
                          return _c(
                            "el-link",
                            {
                              key: index + item.standardCode,
                              staticStyle: { "margin-right": "15px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.quickSearchDictDrug($event, "国家")
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.standardCode))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.dictDrugTableData,
                    height: 300,
                    border: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    stripe: "",
                  },
                  on: { "sort-change": _vm.drugDictTableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "registeredProductName",
                      label: "药品名称",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "displayName",
                      label: "规格剂型",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.registeredMedicineModel) +
                                _vm._s(scope.row.registeredOutlook) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "realitySpec",
                      label: "实际规格",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "materialName",
                      label: "包装",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.materialName) +
                                "(" +
                                _vm._s(scope.row.factor) +
                                "/" +
                                _vm._s(scope.row.minUnit) +
                                "/" +
                                _vm._s(scope.row.unit) +
                                ") "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "companyNamesc",
                      label: "厂家",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "approvalCode",
                      label: "批准文号",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "goodsStandardCode",
                      label: "本位码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "goodsCode",
                      label: "国家医保编码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "80",
                      property: "comment",
                      label: "备注信息",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "120", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDrugDictTableRow(
                                      _vm.nowEditInputIndex,
                                      scope.row,
                                      "国家"
                                    )
                                  },
                                },
                              },
                              [_vm._v("选择 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDrugComment(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("编辑备注 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dictDrugForm.total > 0,
                    expression: "dictDrugForm.total>0",
                  },
                ],
                attrs: {
                  total: _vm.dictDrugForm.total,
                  page: _vm.dictDrugForm.pageNum,
                  limit: _vm.dictDrugForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.selectDictDrug("国家")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "上海医保药品目录",
            width: "80%",
            "append-to-body": "",
            visible: _vm.smpaDrugDictDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.smpaDrugDictDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "drugDictQueryForm",
                  attrs: { "status-icon": "", model: _vm.dictDrugForm },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px", float: "left" },
                        attrs: {
                          placeholder: "请输入药品名称/批准文号/本位码",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.selectDictDrug("上海")
                          },
                        },
                        model: {
                          value: _vm.dictDrugForm.drugName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dictDrugForm, "drugName", $$v)
                          },
                          expression: "dictDrugForm.drugName",
                        },
                      }),
                      _c("el-input", { staticStyle: { display: "none" } }),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                          },
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.selectDictDrug("上海")
                            },
                          },
                        },
                        [_vm._v("检索 ")]
                      ),
                      _c(
                        "span",
                        { staticStyle: { margin: "0px 10px 0px 30px" } },
                        [_vm._v("快捷搜索:")]
                      ),
                      _vm._l(_vm.dkbDp.dkbDpAliasList, function (item, index) {
                        return _c(
                          "el-link",
                          {
                            key: index + item.alias,
                            staticStyle: { "margin-right": "15px" },
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.quickSearchDictDrug($event, "上海")
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.alias))]
                        )
                      }),
                      _vm._l(
                        _vm.dkbDp.dkbDpApprovalNumList,
                        function (item, index) {
                          return _c(
                            "el-link",
                            {
                              key: index + item.approvalNum,
                              staticStyle: { "margin-right": "15px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.quickSearchDictDrug($event, "上海")
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.approvalNum))]
                          )
                        }
                      ),
                      _vm._l(
                        _vm.dkbDp.dkbDpStandardCodeList,
                        function (item, index) {
                          return _c(
                            "el-link",
                            {
                              key: index + item.standardCode,
                              staticStyle: { "margin-right": "15px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.quickSearchDictDrug($event, "上海")
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.standardCode))]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.dictDrugTableData,
                    height: 300,
                    border: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    stripe: "",
                  },
                  on: { "sort-change": _vm.drugDictTableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "yptym",
                      label: "药品名称",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "displayName",
                      label: "剂型规格包装",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.jx) +
                                "--" +
                                _vm._s(scope.row.ggbz) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "ggbzwzbs",
                      label: "包装",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "scqy",
                      label: "厂家",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "pzwh",
                      label: "批准文号",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "bwm",
                      label: "本位码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "tbdm",
                      label: "上海医保编码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "80",
                      property: "comment",
                      label: "备注信息",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "120", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDrugDictTableRow(
                                      _vm.nowEditInputIndex,
                                      scope.row,
                                      "上海"
                                    )
                                  },
                                },
                              },
                              [_vm._v("选择 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDrugComment(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("编辑备注 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dictDrugForm.total > 0,
                    expression: "dictDrugForm.total>0",
                  },
                ],
                attrs: {
                  total: _vm.dictDrugForm.total,
                  page: _vm.dictDrugForm.pageNum,
                  limit: _vm.dictDrugForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.selectDictDrug("上海")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "备注信息编辑",
            width: "500px",
            "append-to-body": "",
            visible: _vm.editDrugCommentDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDrugCommentDialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "450px" },
            attrs: {
              type: "textarea",
              rows: 6,
              placeholder: "请输入备注信息",
              maxlength: "500",
              clearable: "",
              "show-word-limit": "",
            },
            model: {
              value: _vm.drugCommentForm.comment,
              callback: function ($$v) {
                _vm.$set(_vm.drugCommentForm, "comment", $$v)
              },
              expression: "drugCommentForm.comment",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDrugCommentDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitEditDrugComment },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }