/**
 * 厂商相关
 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

/*根据关键字查询厂商名称或别名，只返回匹配的前50条*/
export function selectManufacturerNameByKey(searchValue) {
  return request({
    url: '/dkm/manufacturer/selectManufacturerNameByKey',
    method: 'post',
    params: { searchValue: searchValue }
  })
}


// 查询厂商列表
export function list(query) {
  return request({
    url: '/dkm/manufacturer/list',
    method: 'post',
    params: query
  })
}


// 查询单个厂商明细
export function info(id) {
  return request({
    url: '/dkm/manufacturer/info/' + praseStrEmpty(id),
    method: 'get'
  })
}

// 删除厂商
export function del(id) {
  return request({
    url: '/dkm/manufacturer/del/' + praseStrEmpty(id),
    method: 'get'
  })
}


// 新增厂商 或 修改厂商
export function saveOrUpdate(data) {
  return request({
    url: '/dkm/manufacturer/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 更新厂商状态--0正常 1停用
export function updateStatus( id,status) {
  return request({
    url: '/dkm/manufacturer/updateStatus',
    method: 'post',
    params: {id:id,status:status}
  })
}


// 根据批准文号和本位码查询关联的厂商列表
export function selectRelationManufacturer( approvalNumListStr,standardCodeListStr) {
  return request({
    url: '/dkm/manufacturer/selectRelationManufacturer',
    method: 'post',
    params: {approvalNumListStr:approvalNumListStr,standardCodeListStr:standardCodeListStr}
  })
}
